import { isNil } from 'lodash';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { useState, useRef, useMemo, useEffect, useCallback } from 'react';

import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from './Step5';
import { DIALOG, NEW_PARTICIPANT_STATUS, PLUMBID_STATUS, ROLES } from 'constant';
import { WizardAlert } from './WizardAlert';
import { useAuthState } from 'service/store';
import { WizardFooter } from './WizardFooter';
import { WizardStepper } from './WizardStepper';
import { PageFooterContainer } from 'components';
import { formatPDFFileName, formatToTitleCase } from 'utils/formatters';
import {
  usePlumBidByIdLazy,
  useDialog,
  useGetMe,
  useNotification,
  useInviteEmailText,
  useResponsive,
  usePollingAdministrating,
} from 'utils/hooks';
import {
  SEND_EMAIL_MUTATION,
  SAVE_WIZARD_MUTATION,
  ADD_OFFER_DOC_MUTATION,
  CREATE_INVITE_MUTATION,
  GET_PLUMBID_BY_ID_QUERY,
  UPDATE_PLUMBID_MUTATION,
  DELETE_PLUMBID_MUTATION,
  PARSE_OFFER_DOC_MUTATION,
  SEND_EMAIL_FINISH_MUTATION,
  SET_PLUMBID_DATES_MUTATION,
  HAS_PLUMBIDS,
} from 'service/graphql';
import { DASHBOARD, HOME } from 'routes';
import { Icons } from 'legos';
import { getParticipantField, theme } from 'utils';
import { hasOfferSettingsError, hasOfferSettingsWarning } from 'utils';
import { useSettingsState } from 'service/store/settingsStore';
import { Button, PBox } from 'legos';
import { RedirectPage } from 'components/RedirectPage';

const wizardFormSteps = [
  'Add your listing',
  'Upload your offers',
  'Add Seller(s) information',
  'Pick your plumBid date',
  'Review Offers',
];

export const WizardContainer = () => {
  const responsive = useResponsive();
  const [, dispatchSettings] = useSettingsState();

  const { fetchMe } = useGetMe();
  const [{ me }] = useAuthState('network-only');
  const isAdmin = useMemo(() => me?.roles?.includes(ROLES.ADMIN), [me]);
  const isLA = useMemo(() => me?.roles?.includes(ROLES.LISTING_AGENT), [me]);
  const showNotification = useNotification();

  const history = useHistory();
  const location = useLocation();
  const currentPlumbidId = Number(location.state?.plumBidId) || me?.wizardStepInfo?.[0];
  const displayOnlyStep5 = location.state?.displayOnlyStep5;
  const [isOfferChanged, setIsOfferChanged] = useState(false);
  const [plumbidId, setPlumbidId] = useState(currentPlumbidId);

  useEffect(() => {
    setPlumbidId(currentPlumbidId);
  }, [currentPlumbidId]);

  const formikRef = useRef();
  const [currentStep, setCurrentStep] = useState(() => {
    if (location.state?.displayOnlyStep5) {
      return 5;
    }

    if (location.state?.step) {
      return Number(location.state?.step);
    }
    if (me?.wizardStepInfo?.[1]) {
      return me?.wizardStepInfo?.[1] > 5 ? 5 : me?.wizardStepInfo?.[1];
    }
    return 1;
  });

  const [files, setFiles] = useState([]);
  const [mlsId, setMlsId] = useState('');
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState(100);
  const [countUploadOffer, setCountUploadOffer] = useState(0);
  const [loadingSendAllInvites] = useState(false);
  const [isOpenSendEmailFinishDialog, setIsOpenSendEmailFinishDialog] = useState(false);

  const [getMyPlumBidsCount] = useLazyQuery(HAS_PLUMBIDS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      closeOkCancelDialog();
      history.push(data?.hasPlumbids ? DASHBOARD : HOME);
    },
    onError: () => {
      closeOkCancelDialog();
      history.push(HOME);
    },
  });
  const [deletePlumbid] = useMutation(DELETE_PLUMBID_MUTATION);
  const [updatePlumbid] = useMutation(UPDATE_PLUMBID_MUTATION);
  const [runSaveWizardStep] = useMutation(SAVE_WIZARD_MUTATION);
  const { plumBid, plumBidLoading, plumbidByIdFetch, plumBidRefetch } = usePlumBidByIdLazy(null, { id: plumbidId });
  const [addOfferDoc, { loading: addOfferDocLoading }] = useMutation(ADD_OFFER_DOC_MUTATION);
  const [sendEmailMutation, { loading: sendEmailLoading }] = useMutation(SEND_EMAIL_MUTATION);
  const [parseOfferDoc, { loading: parseOfferDocLoading }] = useMutation(PARSE_OFFER_DOC_MUTATION);
  const [setPlumbidDates] = useMutation(SET_PLUMBID_DATES_MUTATION);
  const [sendEmailFinish, { loading: sendEmailFinishLoading }] = useMutation(SEND_EMAIL_FINISH_MUTATION);
  const { inviteEmailText, inviteEmailTextRefetch } = useInviteEmailText(plumBid);
  const isAdminInTheWizard = usePollingAdministrating({ plumBid, skip: displayOnlyStep5 });
  const isPlumbidFinished = plumBid?.status === PLUMBID_STATUS.CANCELED || plumBid?.status === PLUMBID_STATUS.FINISHED;

  const [createInvitedMutation, { loading: createInvitedLoading }] = useMutation(CREATE_INVITE_MUTATION);
  const {
    openDialog: openOkCancelDialog,
    closeDialog: closeOkCancelDialog,
    changeProps: changePropsOkCancelDialog,
  } = useDialog(DIALOG.OK_CANCEL);

  const [selectedParty, setSelectedParty] = useState(
    () =>
      location.state?.selectedParty?.id || plumBid?.buyerParty?.find((party) => isNil(party?.offerdoc?.progress))?.id
  );

  const hasPartyWarning = plumBid
    ? plumBid.buyerParty?.some((party) => {
        return (
          hasOfferSettingsWarning(party.offersettings) ||
          hasOfferSettingsError(party.offersettings, party.offersettings)
        );
      })
    : null;

  const saveWizardStep = useCallback(
    (plumbidId) => {
      if (!isAdmin && !displayOnlyStep5) {
        return runSaveWizardStep({
          variables: {
            input: {
              plumbidId: currentStep > 5 ? null : plumbidId,
              step: currentStep > 5 ? null : plumbidId ? currentStep : null,
            },
          },
        })
          .catch((error) => {
            showNotification({ error });
          })
          .finally(() => fetchMe());
      }
      return Promise.resolve();
    },
    [isAdmin, runSaveWizardStep, currentStep, showNotification, fetchMe, displayOnlyStep5]
  );

  useEffect(() => {
    if (plumBid?.mls) {
      setMlsId(plumBid.mls.mlsId);
    }
  }, [plumBid]);

  useEffect(() => {
    if (currentStep === 3) {
      inviteEmailTextRefetch();
    }
  }, [currentStep, inviteEmailTextRefetch]);

  useEffect(() => {
    if (plumbidId && currentStep < 6) {
      saveWizardStep(plumbidId);
      if (typeof plumBidRefetch === 'function') {
        plumBidRefetch({ id: plumbidId });
      } else {
        plumbidByIdFetch({ variables: { id: plumbidId } });
      }
    }
  }, [saveWizardStep, plumBidRefetch, plumbidByIdFetch, plumbidId, currentStep]);

  const sendAllInvitesToBuyersWithEmail = useCallback(
    () =>
      Promise.all(
        plumBid.buyerParty?.map((party) =>
          Promise.all(
            party.participantSet.map(async (participant) => {
              if (
                !getParticipantField(participant, 'email') ||
                [
                  NEW_PARTICIPANT_STATUS.COMPLETED,
                  NEW_PARTICIPANT_STATUS.WAITING_FOR_ONBOARDING,
                  NEW_PARTICIPANT_STATUS.INVITED,
                ].some((status) => status === participant.status)
              ) {
                return Promise.resolve();
              }
              let inviteToken;
              try {
                if (NEW_PARTICIPANT_STATUS.PENDING || !participant.invite) {
                  const { data } = await createInvitedMutation({
                    variables: {
                      input: {
                        participantId: participant.id,
                        email: getParticipantField(participant, 'email'),
                        role: participant.role,
                        fullName: getParticipantField(participant, 'fullName'),
                        plumbidId: plumBid.id,
                        plumbidPartyId: party.id,
                      },
                    },
                  });
                  if (data?.createInvite?.errors) {
                    throw data?.createInvite?.errors;
                  } else {
                    inviteToken = data?.createInvite?.invite.token;
                  }
                } else {
                  inviteToken = participant.invite.token;
                }
                await sendEmailMutation({
                  variables: {
                    input: {
                      email: getParticipantField(participant, 'email'),
                      token: inviteToken,
                      text: inviteEmailText?.[0]?.replace(
                        'Hello',
                        `Hello ${formatToTitleCase(getParticipantField(participant, 'fullName') || '')}`
                      ),
                    },
                  },
                });
              } catch (error) {
                throw error;
              }
            })
          )
        )
      ),
    [createInvitedMutation, inviteEmailText, plumBid?.buyerParty, plumBid?.id, sendEmailMutation]
  );

  const sendAllInvitesToSellersWithEmail = useCallback(
    () =>
      Promise.all(
        plumBid?.sellerParty?.participantSet?.map(async (participant) => {
          if (
            !getParticipantField(participant, 'email') ||
            [
              NEW_PARTICIPANT_STATUS.COMPLETED,
              NEW_PARTICIPANT_STATUS.WAITING_FOR_ONBOARDING,
              NEW_PARTICIPANT_STATUS.INVITED,
            ].some((status) => status === participant.status)
          ) {
            return Promise.resolve();
          }
          try {
            await sendEmailMutation({
              variables: {
                input: {
                  email: getParticipantField(participant, 'email'),
                  token: participant.invite.token,
                  text:
                    getParticipantField(participant, 'fullName') === ''
                      ? `Hello and welcome to plumBid! \n\n ${inviteEmailText?.[1]}`
                      : `Hello ${formatToTitleCase(getParticipantField(participant, 'fullName') || '')}, \n\n ${
                          inviteEmailText?.[1]
                        }`,
                },
              },
            });
          } catch (error) {
            throw error;
          }
        })
      ),
    [inviteEmailText, plumBid?.sellerParty?.participantSet, sendEmailMutation]
  );

  const sendAllInvites = useCallback(() => {
    sendAllInvitesToSellersWithEmail();
    sendAllInvitesToBuyersWithEmail();
    return Promise.resolve();
  }, [sendAllInvitesToBuyersWithEmail, sendAllInvitesToSellersWithEmail]);

  const moveToNextReviewOfferInfo = useMemo(() => {
    const result = plumBid?.buyerParty.reduceRight(
      (acc, party) => {
        if (isNil(party?.offerdoc?.progress)) {
          if (selectedParty === party.id) {
            acc.selectedPartyChecked = true;
          }
          if (
            hasOfferSettingsWarning(party.offersettings) ||
            hasOfferSettingsError(party.offersettings, party.offersettings)
          ) {
            acc.countPartyToReview += 1;
            if (selectedParty === party.id) {
              acc.selectedPartyToReview = true;
            }
            if (!acc.selectedPartyChecked) {
              acc.nextPartyToReview = party.id;
            }
            acc.firstOfferToReview = party.id;
          }
        }

        return acc;
      },
      {
        countPartyToReview: 0,
        nextPartyToReview: null,
        firstOfferToReview: null,
        selectedPartyChecked: false,
        selectedPartyToReview: false,
      }
    );

    if (result && result.countPartyToReview > 0 && !result.nextPartyToReview) {
      result.nextPartyToReview = result.firstOfferToReview;
    }
    return result;
  }, [plumBid?.buyerParty, selectedParty]);

  const handleFinish = useCallback(() => {
    if (moveToNextReviewOfferInfo?.countPartyToReview > 0) {
      setSelectedParty(moveToNextReviewOfferInfo?.nextPartyToReview);
    }

    if (hasPartyWarning) {
      openOkCancelDialog({
        title: 'Attention',
        text: (
          <Typography>
            Parties marked with <Icons.WarningCircle /> sign need to have offer details corrected, please review.
          </Typography>
        ),

        okTitle: 'OK',
        hideCancel: true,
        onOk: closeOkCancelDialog,
      });
    } else if (displayOnlyStep5) {
      sendAllInvitesToBuyersWithEmail()
        .then(setIsOfferChanged(false))
        .then(history.goBack)
        .catch((error) => showNotification({ error }));
    } else {
      sendAllInvites().catch((error) => showNotification({ error }));
      saveWizardStep(null).finally(() => {
        history.push(DASHBOARD);
        dispatchSettings({
          type: 'refreshScreen',
          payload: 'DashboardPage',
        });
      });
    }
  }, [
    moveToNextReviewOfferInfo?.countPartyToReview,
    moveToNextReviewOfferInfo?.nextPartyToReview,
    hasPartyWarning,
    displayOnlyStep5,
    openOkCancelDialog,
    closeOkCancelDialog,
    sendAllInvitesToBuyersWithEmail,
    history,
    showNotification,
    sendAllInvites,
    saveWizardStep,
    dispatchSettings,
  ]);

  const processingInfo = useMemo(() => {
    if (plumBid?.buyerParty?.length) {
      const processing = plumBid.buyerParty.reduce(
        (accumulator, party) => {
          if (!isNil(party?.offerdoc?.progress)) {
            accumulator.percentProcessingFiles += party.offerdoc.progress;
            accumulator.isRunProcessing = true;
            accumulator.countProcessingFiles += 1;
          }
          return accumulator;
        },
        {
          countProcessingFiles: 0,
          percentProcessingFiles: 0,
          isRunProcessing: false,
          totalFiles: countUploadOffer,
        }
      );

      if (processing.countProcessingFiles > 0) {
        processing.percentProcessingFiles += (processing.totalFiles - processing.countProcessingFiles) * 100;
        processing.percentProcessingFiles /= processing.totalFiles;
      }

      return processing;
    }
    return {
      countProcessingFiles: 0,
      percentProcessingFiles: 0,
      isRunProcessing: false,
      totalFiles: 0,
    };
  }, [countUploadOffer, plumBid?.buyerParty]);

  useEffect(() => {
    if (plumBid?.buyerParty.length) {
      const countProcessing = plumBid.buyerParty.reduce((accumulator, party) => {
        if (!isNil(party?.offerdoc?.progress)) {
          accumulator++;
        }
        return accumulator;
      }, 0);
      setCountUploadOffer((oldValue) => (oldValue < countProcessing ? countProcessing : oldValue));
    }
  }, [plumBid?.buyerParty]);

  const onUploadFiles = (files) => {
    files = [...files];
    setUploadingProgress(0);
    setFiles(files.map((file, index) => ({ offerdoc: { offerFile: file.name }, uploading: true, id: index })));
    Promise.all(
      files.map(async (file, index) => {
        return addOfferDoc({
          variables: {
            input: {
              pdfFile: file,
              plumbidId,
            },
          },
        })
          .then((res) => {
            if (res?.data?.addOfferDoc?.success) {
              setFiles((oldFiles) => {
                const newFiles = [...oldFiles];
                if (newFiles[index]) {
                  delete newFiles[index].uploading;
                  newFiles[index].offerFile = formatPDFFileName(res?.data?.addOfferDoc.offerDoc.filename);
                }
                return newFiles;
              });
            }
            return parseOfferDoc({
              variables: {
                input: {
                  uploadId: res?.data?.addOfferDoc?.offerDoc.id,
                },
              },
              refetchQueries: [
                {
                  query: GET_PLUMBID_BY_ID_QUERY,
                  variables: {
                    id: plumbidId,
                  },
                },
              ],
            });
          })
          .then(() =>
            setUploadingProgress((oldValue) => {
              const newValue = oldValue + Math.round(100 / files.length);
              return newValue;
            })
          );
      })
    ).finally(() => {
      plumBidRefetch().finally(() => {
        setFiles([]);
        setUploadingProgress(100);

        if (processingInfo.isRunProcessing) {
          setCountUploadOffer(countUploadOffer + files.length);
        } else {
          setCountUploadOffer(files.length);
        }
      });
    });
  };

  const handleNextStep = useCallback(() => {
    if (currentStep === 4) {
      if (formikRef.current?.submitForm) {
        formikRef.current.submitForm().then((res) => (res ? setCurrentStep(currentStep + 1) : null));
      }
      return;
    }

    setCurrentStep((currentStep) => {
      const newStep = currentStep + 1;
      return newStep;
    });
  }, [currentStep]);

  const handlePrevStep = useCallback(() => {
    if (currentStep === 4) {
      if (formikRef.current?.submitForm) {
        formikRef.current.submitForm().then((res) => (res ? setCurrentStep(currentStep - 1) : null));
      }
      return;
    }

    setCurrentStep((currentStep) => {
      const newStep = currentStep - 1;

      return newStep;
    });
  }, [currentStep]);

  const handleFirstStep = () => {
    if (moveToNextReviewOfferInfo?.countPartyToReview > 0) {
      setSelectedParty(moveToNextReviewOfferInfo.nextPartyToReview);
      return;
    }
    setCurrentStep(1);
  };

  const handleCancel = () => {
    if (plumbidId) {
      openOkCancelDialog({
        title: 'Attention',
        text: 'Are you sure you want to cancel adding this plumBid?',
        text2: 'The data will not be saved',
        okTitle: 'OK',
        onOk: async () => {
          try {
            changePropsOkCancelDialog({ loading: true });
            if (plumbidId) {
              await deletePlumbid({
                variables: {
                  input: {
                    plumbidId,
                  },
                },
              });
            }
          } catch (error) {
          } finally {
            saveWizardStep(null);
            closeOkCancelDialog();
            getMyPlumBidsCount();
          }
        },
      });
    } else {
      history.goBack();
    }
  };

  const handleUpdatePlumbid = useCallback(() => {
    if (plumbidId) {
      handleNextStep();
      updatePlumbid({
        variables: {
          input: {
            plumBidId: plumbidId,
            isAdministrating: 0,
          },
        },
      });
    }
  }, [handleNextStep, plumbidId, updatePlumbid]);

  const handleAdminExit = useCallback(() => {
    openOkCancelDialog({
      title: 'Important',
      text: 'Are you sure you want to exit from editing this plumBid?',
      text2: 'All data will be saved',
      okTitle: 'OK',
      onOk: async () => {
        try {
          changePropsOkCancelDialog({ loading: true });
          handleUpdatePlumbid();
        } catch (error) {
        } finally {
          closeOkCancelDialog();
          history.goBack();
        }
      },
    });
  }, [changePropsOkCancelDialog, closeOkCancelDialog, handleUpdatePlumbid, history, openOkCancelDialog]);

  useEffect(() => {
    if (!displayOnlyStep5 && isAdmin && plumBid?.status !== PLUMBID_STATUS.INCOMPLETE && plumBid?.status) {
      openOkCancelDialog({
        title: 'Attention',
        text: 'Listing Agent has finished adding a plumBid',
        okTitle: 'OK',
        hideCancel: true,
        onOk: () => {
          saveWizardStep(null).then(history.push(DASHBOARD));
          closeOkCancelDialog();
        },
        onClose: () => {
          saveWizardStep(null).then(history.push(DASHBOARD));
          closeOkCancelDialog();
        },
      });
    }
  }, [closeOkCancelDialog, displayOnlyStep5, history, isAdmin, openOkCancelDialog, plumBid?.status, saveWizardStep]);

  useEffect(() => {
    if (isAdmin) {
      window.addEventListener('unload', handleUpdatePlumbid);
      return () => window.removeEventListener('beforeunload', handleUpdatePlumbid);
    }
  }, [handleUpdatePlumbid, isAdmin]);

  const handleSendEmailFinish = useCallback(() => {
    sendEmailFinish({
      variables: {
        input: { plumbidId: plumbidId },
      },
    })
      .catch((error) =>
        showNotification({
          message: 'Something went wrong',
          variant: 'error',
        })
      )
      .finally(() => {
        fetchMe();
        plumBidRefetch();
        setIsOpenSendEmailFinishDialog(false);
      });
  }, [sendEmailFinish, fetchMe, showNotification, plumbidId, plumBidRefetch]);

  const nextLoading = createInvitedLoading || sendEmailLoading || loadingSendAllInvites;
  const isFinishDisabled = processingInfo.countProcessingFiles > 0;
  const saveButtonLoading = addOfferDocLoading || parseOfferDocLoading || isFinishDisabled;

  return !isAdmin && !isLA ? (
    <RedirectPage />
  ) : !plumBid?.status || plumBid?.status === PLUMBID_STATUS.INCOMPLETE || displayOnlyStep5 ? (
    <>
      <Box px={responsive.mobileScreen ? 3 : 6}>
        {!displayOnlyStep5 && (
          <WizardStepper steps={wizardFormSteps} currentStep={currentStep} responsive={responsive} />
        )}
        <Box pt={responsive.tabletScreen ? 2 : 6}>
          {currentStep === 1 && (
            <Step1
              mlsId={mlsId}
              plumbid={plumBid}
              setMlsId={setMlsId}
              plumbidId={plumbidId}
              responsive={responsive}
              setPlumbidId={setPlumbidId}
              plumBidLoading={plumBidLoading}
              saveWizardStep={saveWizardStep}
              plumbidByIdFetch={plumbidByIdFetch}
              setIsNextDisabled={setIsNextDisabled}
            />
          )}
          {currentStep === 2 && plumBid && (
            <Step2
              files={files}
              plumBid={plumBid}
              setFiles={setFiles}
              responsive={responsive}
              onUploadFiles={onUploadFiles}
              plumBidLoading={plumBidLoading}
              uploadingProgress={uploadingProgress}
              plumBidRefetch={plumBidRefetch}
            />
          )}
          {currentStep === 3 && plumBid && (
            <Step3
              ref={formikRef}
              plumBid={plumBid}
              plumbidId={plumbidId}
              responsive={responsive}
              inviteEmailText={inviteEmailText}
              setIsNextDisabled={setIsNextDisabled}
              createInvitedMutation={createInvitedMutation}
              createInvitedLoading={createInvitedLoading}
            />
          )}
          {currentStep === 4 && plumBid && (
            <Step4
              ref={formikRef}
              plumBid={plumBid}
              responsive={responsive}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              setPlumbidDates={setPlumbidDates}
              setIsNextDisabled={setIsNextDisabled}
            />
          )}

          {currentStep === 5 && plumBid && (
            <Step5
              plumBid={plumBid}
              plumBidLoading={plumBidLoading}
              plumBidRefetch={plumBidRefetch}
              handleUploadFile={onUploadFiles}
              setIsNextDisabled={setIsNextDisabled}
              setIsOfferChanged={setIsOfferChanged}
              displayOnlyOneStep={displayOnlyStep5}
              closeOkCancelDialog={closeOkCancelDialog}
              processingInfo={processingInfo}
              createInvitedMutation={createInvitedMutation}
              isOpenSendEmailFinishDialog={isOpenSendEmailFinishDialog}
              fileUploading={addOfferDocLoading || parseOfferDocLoading}
              setIsOpenSendEmailFinishDialog={setIsOpenSendEmailFinishDialog}
              defaultSelectedParty={location.state?.selectedParty}
              readOnly={isPlumbidFinished}
              uploadingProgress={uploadingProgress}
              setCountUploadOffer={setCountUploadOffer}
              countUploadOffer={countUploadOffer}
              selectedParty={selectedParty}
              setSelectedParty={setSelectedParty}
            />
          )}
        </Box>
        <Box mb={responsive.mobileScreen ? 40 : 25} />
      </Box>

      <PageFooterContainer id="wizard-footer">
        <WizardFooter
          displayOnlyStep5={displayOnlyStep5}
          warning={hasPartyWarning}
          onCancel={handleCancel}
          responsive={responsive}
          nextLoading={nextLoading}
          currentStep={currentStep}
          onPrevStep={handlePrevStep}
          onNextStep={handleNextStep}
          onFinishStep={handleFinish}
          onFirstStep={handleFirstStep}
          isFirstStep={currentStep === 1}
          isNextDisabled={isNextDisabled}
          isFinishDisabled={isFinishDisabled}
          processingInfo={processingInfo}
          countTotalFiles={plumBid?.buyerParty.length}
          isLastStep={currentStep === wizardFormSteps.length}
          mlsId={plumBid?.mls}
          history={history}
          isOfferChanged={isOfferChanged}
          saveButtonLoading={saveButtonLoading}
          isPlumbidFinished={isPlumbidFinished}
          isAdmin={isAdmin}
          onExit={handleAdminExit}
          plumBid={plumBid}
          isAdminInTheWizard={isAdminInTheWizard}
          moveToNextReviewOfferInfo={moveToNextReviewOfferInfo}
          uploadingProgress={uploadingProgress}
        />
      </PageFooterContainer>

      <WizardAlert
        isOpen={isOpenSendEmailFinishDialog}
        onClose={() => setIsOpenSendEmailFinishDialog(false)}
        onOk={handleSendEmailFinish}
        loading={sendEmailFinishLoading}
      />
    </>
  ) : (
    !isAdmin && (
      <Box display="flex" alignItems="center">
        <PBox py={10} px={10}>
          <Button
            color="white"
            align="center"
            title="Go to Dashboard"
            onClick={() => {
              saveWizardStep(null).then(history.push(DASHBOARD));
            }}
            style={{
              backgroundColor: theme.palette.primary.main,
              minWidth: '150px',
              height: '40px',
            }}
          />
        </PBox>
        <Typography>Your plumBid not in Initialize status. To proceed go to Dashboard</Typography>
      </Box>
    )
  );
};
