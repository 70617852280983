export const SellerPrioritiesType = `
  id
  amountDown
  idealEscrowLength
  escrowPriority
  inspectionPriority
  loanContingencyPriority
  appraisalContingencyPriority
  leaseBackLength
  leaseBackImportance
  sellerContingencyPriority
  buyerWarrantyPriority
  buyerContingencyPriority
  offerContingent
  offerContingentSecondary
  percentage {
    id
    cash
    escrow
    inspection
    loan
    appraisal
    leaseback
    cop
  }
`;

export const CommonPlumbidType = `
  notifyOnParsed
  owner {
    userId
    fullName
    phone
    email
  }
  active
  createdAt
  startDateTime
  finishDateTime
  listPrice
  status
  isAdministrating
  plumbidpartySet {
    id
    isDuplicate
    party
    isWinner
    status
    onboardingStatus
    sellerPriorities {
      ${SellerPrioritiesType}
    }
    buyerPriorities {
      offerContingent
      offerContingentSecondary
    }
    offerdoc {
      offerFile
      filename
      offerFileUrl
      id
      error
      progress
      parseEndedAt
    }
    contingencyRemoved
    secondaryContingencyRemoved
    offersettings {
      id
      needHelp
      phone
      offerDoc {
        id
      }
      offerDateImage
      buyerWarrantyImage
      buyerImage
      buyerAgentsImage
      agreementContingent
      agreementContingentImage
      offerPrice
      offerPriceIsPrecise
      offerPriceImage
      propertyAddress
      propertyAddressIsPrecise
      propertyAddressImage
      cashdownAmount
      cashdownAmountIsPrecise
      cashdownAmountImage
      escrowLength
      escrowLengthIsPrecise
      escrowPeriodImage
      isVaLoan
      isFhaLoan
      inspectionContingency
      inspectionContingencyIsPrecise
      inspectionContingencyLength
      inspectionContingencyLengthIsPrecise
      inspectionContingencyImage
      loanContingency
      loanContingencyIsPrecise
      loanContingencyLength
      loanContingencyLengthIsPrecise
      loanContingencyImage
      appraisalContingency
      appraisalContingencyIsPrecise
      appraisalContingencyLength
      appraisalContingencyLengthIsPrecise
      appraisalContingencyImage
      offerLeaseBack
      offerLeaseBackIsPrecise
      offerLeaseBackLength
      offerLeaseBackLengthIsPrecise
      offerLeaseBackPricePerDay
      offerLeaseBackPricePerDayIsPrecise
      offerLeaseBackImage
      offerFromImage
    }
    participants {
      edges {
        node {
          id
          role
          active
          backUpPosition
          isParsed
          confirmedAnswers
          popupStatus {
            id
            backUpPosition
            pending
            plumBidStarted
            timeLeftIncrease
            newBidReceived
            finishDateHasChanged
          }
          onboardingStatus {
            mismatch
            review
            incorrectOffer
            pending
          }
          preferences {
            ${SellerPrioritiesType}
          }
          user {
            userId
            email
            fullName
          }
          offerParticipant {
            id
            fullName
            email
          }
          status
          invite {
            id
            fullName
            createdAt
            acceptedAt
            token
            email
            active
            link
            status
          }
          selleranswerSet {
            id
            question
            answer
            answerValue
          }
        }
      }
    }
  }
  mls {
    id
    mlsId
    title
    fullDescription
    previewImage
    listingPrice
    dateTaken
    country
    zipCode
    state
    city
    address
    latitude
    longitude
    propertySize
    lotSize
    numberOfBedrooms
    numberOfBathrooms
    yearBuilt
    mlsinfophotoSet {
      picture
    }
  }

`;

export const AdminPlumbidType = `
  id: plumbidId
  plumbidId
  timeLeft {
    timeLeft
  }
  ${CommonPlumbidType}
`;

export const PlumBidForOptimizer = `
  id
  isSellerPartyReady
  buyerPartiesCount
  buyerPartiesReady
  totalBidders
  readyBidders
  timeLeft {
    timeLeft
  }
  totalBids
  lastBid {
    id
    partyId
    owner {
      user {
        userId
        fullName
      }
    }
    plumBidAmount
  }
  startDateTime
  finishDateTime
  listPrice
  status
  plumbidpartySet {
    id
    party
    isWinner
    status
    onboardingStatus
    sellerPriorities {
      ${SellerPrioritiesType}
    }
    buyerPriorities {
      offerContingent
      offerContingentSecondary
    }
    contingencyRemoved
    secondaryContingencyRemoved
    offersettings {
      id
      agreementContingent
      offerPrice
      cashdownAmount
      escrowLength
      isVaLoan
      isFhaLoan
      inspectionContingency
      inspectionContingencyLength
      loanContingency
      loanContingencyLength
      appraisalContingency
      appraisalContingencyLength
      offerLeaseBack
      offerLeaseBackLength
      offerLeaseBackPricePerDay
      offerLeaseBackPricePerDayIsPrecise
    }
    participants {
      edges {
        node {
          id
          role
          backUpPosition
          popupStatus {
            id
            backUpPosition
            pending
            plumBidStarted
            timeLeftIncrease
            newBidReceived
            finishDateHasChanged
          }
          onboardingStatus {
            mismatch
            review
            incorrectOffer
            pending
          }
          user {
            userId
            email
            fullName
          }
          status
        }
      }
    }
  }
  mls {
    id
    mlsId
    title
    fullDescription
    previewImage
    listingPrice
    dateTaken
    country
    zipCode
    state
    city
    address
    latitude
    longitude
    propertySize
    lotSize
    numberOfBedrooms
    numberOfBathrooms
    yearBuilt
    mlsinfophotoSet {
      picture
    }
  }
`;

export const PlumBidType = `
  id
  isSellerPartyReady
  buyerPartiesCount
  buyerPartiesReady
  totalBidders
  readyBidders
  timeLeft {
    timeLeft
  }
  lastBid {
    id
    partyId
    owner {
      user {
        userId
        fullName
      }
    }
  }
  ${CommonPlumbidType}

`;

export const BidType = `
  partyId
  owner {
    user {
      id
      userId
      fullName
      email
    }
  }
  id
  createdAt
  mcai
  price
  amountDown
  percentageDown
  escrowLength
  vaLoan
  leaseBackIncentive
  inspectionContingency
  inspectionContingencyLength
  loanContingency
  loanContingencyLength
  appraisalContingency
  appraisalContingencyLength
  offerLeaseBack
  sellerContingency
  buyerWarranty
  buyerContingency
  downPaymentIncentive
  escrowIncentive
  inspectionContingencyIncentive
  inspectionContingencyLengthIncentive
  loanContingencyIncentive
  loanContingencyLengthIncentive
  appraisalContingencyIncentive
  appraisalContingencyLengthIncentive
  leaseBackIncentive
  sellerContingencyIncentive
  buyerWarrantyIncentive
  buyerContingencyIncentive
  totalIncentives
  plumBidAmount
`;

export const UserType = `
  id
  fullName
  phone
  email
  dreId
  isListingAgent
  isVerifiedAgent
  isActive
  isAdmin
  participants {
    edges {
      node {
        role
      }
    }
  }
`;

export const ExtendedUserType = `
  id
  userId
  fullName
  phone
  email
  dreId
  roles
  callMeBackPhone
  isVerifiedAgent
  acceptSms
  isSeenVerification
  listingAgentStatus
  wizardStepInfo
`;

export const ChatMessageType = `
  id
  owner {
    id
    fullName
  }
  createdAt
  message
`;

export const SBBidType = `
  id
  owner {
    user {
      id
      key
      fullName
    }
  }
  createdAt
  mcai
  price
  amountDown
  percentageDown
  escrowLength
  vaLoan
  inspectionContingency
  inspectionContingencyLength
  loanContingency
  loanContingencyLength
  appraisalContingency
  appraisalContingencyLength
  offerLeaseBack
  sellerContingency
  buyerWarranty
  buyerContingency
  downPaymentIncentive
  escrowIncentive
  inspectionContingencyIncentive
  inspectionContingencyLengthIncentive
  loanContingencyIncentive
  loanContingencyLengthIncentive
  appraisalContingencyIncentive
  appraisalContingencyLengthIncentive
  leaseBackIncentive
  sellerContingencyIncentive
  buyerWarrantyIncentive
  buyerContingencyIncentive
  totalIncentives
  plumBidAmount
  leaseBackIncentiveDisplay
  sellerContingencyIncentiveDisplay
  offerId
`;

export const SBAdminPlumbidType = `
  id

  active
  createdAt
  startDateTime
  finishDateTime
  listPrice
  status
  mls {
    title
    previewImage
    listingPrice
  }
  sbbidSet {
    ${SBBidType}
  }
  plumbidId
  timeLeft{
    timeLeft
  }
`;

export const SBPlumBidType = `
  id
  owner {
    id
    fullName
    key
  }
  mls {
    title
    previewImage
    listingPrice
  }
  active
  createdAt
  startDateTime
  finishDateTime
  listPrice
  status
  sbsellerprioritiesSet {
    amountDown
    idealEscrowLength
    escrowPriority
    inspectionPriority
    loanContingencyPriority
    appraisalContingencyPriority
    leaseBackLength
    leaseBackImportance
    sellerContingencyPriority
    buyerWarrantyPriority
    buyerContingencyPriority
    percentage {
      id
      cash
      escrow
      inspection
      loan
      appraisal
      leaseback
      cop
    }
  }
  sbplumbidpartySet {
    id
    party
    isWinner
    sbparticipantSet {
      edges {
        node {
          role
          active
          user {
            id
            fullName
            key
            timeLeftIncrease
          }
        }
      }
    }
  }
  sbbidSet {
    id
    owner {
      user {
        id
        key
        fullName
      }
    }
    createdAt
    mcai
    price
    amountDown
    percentageDown
    escrowLength
    vaLoan
    inspectionContingency
    inspectionContingencyLength
    loanContingency
    loanContingencyLength
    appraisalContingency
    appraisalContingencyLength
    offerLeaseBack
    sellerContingency
    buyerWarranty
    buyerContingency
    downPaymentIncentive
    escrowIncentive
    inspectionContingencyIncentive
    inspectionContingencyLengthIncentive
    loanContingencyIncentive
    loanContingencyLengthIncentive
    appraisalContingencyIncentive
    appraisalContingencyLengthIncentive
    leaseBackIncentive
    sellerContingencyIncentive
    buyerWarrantyIncentive
    buyerContingencyIncentive
    totalIncentives
    plumBidAmount
    leaseBackIncentiveDisplay
    sellerContingencyIncentiveDisplay
    offerId
  }

  timeLeft {
    timeLeft
  }
`;

export const PlumBidOptimized = `
  id
  isSellerPartyReady
  buyerPartiesCount
  buyerPartiesReady
  totalBidders
  readyBidders
  timeLeft {
    timeLeft
  }
  totalBids
  lastBid {
    id
    partyId
    owner {
      user {
        userId
        fullName
      }
    }
  }
  finishDateTime
  status
  plumbidpartySet {
    id
    party
    isWinner
    status
    onboardingStatus
    sellerPriorities {
      ${SellerPrioritiesType}
    }
    participants {
      edges {
        node {
          id
          role
          backUpPosition

          popupStatus {
            id
            backUpPosition
            pending
            plumBidStarted
            timeLeftIncrease
            newBidReceived
            finishDateHasChanged
          }
          onboardingStatus {
            mismatch
            review
            incorrectOffer
            pending
          }
          offerParticipant {
            id
            fullName
            email
          }
          user {
            userId
            email
            fullName
          }
          status
          invite {
            id
            fullName
            token
            email
            link
          }
        }
      }
    }
  }
  mls {
    id
    mlsId
    title
    fullDescription
    previewImage
    listingPrice
    dateTaken
    country
    zipCode
    state
    city
    address
    latitude
    longitude
    propertySize
    lotSize
    numberOfBedrooms
    numberOfBathrooms
    yearBuilt
    mlsinfophotoSet {
      picture
    }
  }

`;
