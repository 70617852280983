import { useCallback, useEffect, useMemo, useState } from 'react';
import { Collapse, Typography, Grid } from '@material-ui/core';

import { ONBOARDING_STATUS, ROLES } from 'constant';
import { Icons, LinkButton, PBox, SplashDot, Tooltip } from 'legos';
import { BuyerParticipantItem } from './BuyerParticipantItem';
import { getBuyerPartyWarningMessage, getParticipantField } from 'utils';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';
import { hasOfferSettingsError, hasOfferSettingsWarning } from 'utils';

export const BuyerPartyItem = ({
  plumBid,
  party,
  handleViewOffer,
  showInBuyerOnboarding,
  setSelectParticipant,
  selectedParticipant,
}) => {
  const [expanded, setExpanded] = useState(true);
  const { smallScreen } = useResponsive();

  const isIncorrectOffer = party.onboardingStatus[ONBOARDING_STATUS.INCORRECT];

  const markOfferWithMismatch =
    hasOfferSettingsWarning(party.offersettings) || hasOfferSettingsError(party.offersettings, party.offersettings);

  useEffect(() => {
    if (selectedParticipant?.partyId === party?.id && !expanded) {
      setExpanded(true);
    }
  }, [selectedParticipant, party?.id, expanded]);

  const onViewOffer = useCallback(() => {
    handleViewOffer(party);
  }, [handleViewOffer, party]);

  const handleSelectParticipant = useCallback(
    participant => {
      setSelectParticipant({
        participantId: participant.id,
        partyId: party.id,
        buyerId: participant.user?.userId,
        status: participant.status,
        onboardingStatus: participant.onboardingStatus,
        offerContingent: party.buyerPriorities?.offerContingent,
        contingentRemoved: party.contingentRemoved,
        secondaryContingentRemoved: party.secondaryContingentRemoved,
        fullName: getParticipantField(participant, 'fullName'),
      });
    },
    [
      party?.buyerPriorities?.offerContingent,
      party?.contingentRemoved,
      party?.id,
      party?.secondaryContingentRemoved,
      setSelectParticipant,
    ]
  );

  const handleSelectParty = useCallback(() => {
    if (expanded) {
      if (
        selectedParticipant &&
        party.participantSet?.some(participant => participant.id === selectedParticipant.participantId)
      ) {
        setSelectParticipant();
      }
    } else {
      if (!selectedParticipant && party?.participantSet?.[0]) {
        setSelectParticipant({
          partyId: party.id,
          offerContingent: party.buyerPriorities?.offerContingent,
          buyerId: party.participantSet[0].user?.userId,
          participantId: party.participantSet?.[0].id,
          status: party.participantSet?.[0].status,
          onboardingStatus: party.participantSet?.[0].onboardingStatus,
          contingentRemoved: party.contingentRemoved,
          secondaryContingentRemoved: party.secondaryContingentRemoved,
          fullName: getParticipantField(party.participantSet?.[0], 'fullName'),
        });
      }
    }
    setExpanded(!expanded);
  }, [
    expanded,
    party?.buyerPriorities?.offerContingent,
    party?.contingentRemoved,
    party?.id,
    party?.participantSet,
    party?.secondaryContingentRemoved,
    selectedParticipant,
    setSelectParticipant,
  ]);

  const handleArrowClick = useCallback(() => {
    if (expanded && selectedParticipant?.partyId === party.id) {
      setSelectParticipant();
    }
    setExpanded(!expanded);
    if (showInBuyerOnboarding && !expanded) {
      handleSelectParty();
    }
  }, [
    expanded,
    handleSelectParty,
    party?.id,
    selectedParticipant?.partyId,
    setSelectParticipant,
    showInBuyerOnboarding,
  ]);

  const handleContextClick = useCallback(() => {
    setExpanded(!expanded);
    if (showInBuyerOnboarding && !expanded) {
      handleSelectParty();
    }
  }, [expanded, handleSelectParty, showInBuyerOnboarding]);

  const partyStatusProps = useMemo(() => {
    if (party) {
      return getBuyerPartyWarningMessage(party, plumBid.status);
    }
    return null;
  }, [party, plumBid.status]);

  const participantSet = party.participantSet.reduce(
    (acc, participant) => {
      if (participant.role === ROLES.BUYER) {
        acc.buyers.push(participant);
      }
      if (participant.role === ROLES.BUYER_AGENT) {
        acc.buyerAgents.push(participant);
      }
      return acc;
    },
    { buyers: [], buyerAgents: [] }
  );

  return (
    <>
      <PBox pt={1.25} height={30}>
        <Grid container>
          <Grid item xs="auto" onClick={handleArrowClick}>
            <PBox
              mr={1}
              style={{
                cursor: 'pointer',
                transition: 'all 0.2s ',
                transformOrigin: 'center',
                transform: expanded ? 'rotate(0deg)' : 'rotate(90deg)',
              }}
            >
              <Icons.ArrowRight />
            </PBox>
          </Grid>

          <Grid xs item zeroMinWidth style={{ cursor: 'pointer' }} onClick={handleContextClick}>
            <Typography
              variant="h5"
              noWrap
              style={{ fontWeight: 500, overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 300 }}
            >
              {party.partyName}
            </Typography>

            <Typography
              style={{
                fontSize: 11,
                lineHeight: 1.2,
                fontWeight: 400,
                color: partyStatusProps?.color ?? theme.palette.text.warning,
              }}
            >
              {partyStatusProps?.text}
            </Typography>
          </Grid>

          {!showInBuyerOnboarding && (
            <Grid item xs="auto">
              <PBox display="flex" alignItems="center">
                {markOfferWithMismatch && (
                  <PBox mt={-0.5}>
                    <Tooltip text="Click on 'View offer' to finish adding buyer offer" placement="right-start">
                      <Icons.WarningCircle />
                    </Tooltip>
                  </PBox>
                )}
                <LinkButton
                  icon="ViewEye"
                  title={smallScreen ? '' : 'View Offer'}
                  color={theme.palette.primary.main}
                  style={{
                    fontWeight: 500,
                    whiteSpace: 'noWrap',
                    marginRight: smallScreen ? 5 : 0,
                    position: 'relative',
                  }}
                  onClick={onViewOffer}
                >
                  {isIncorrectOffer && <SplashDot />}
                </LinkButton>
              </PBox>
            </Grid>
          )}
        </Grid>
      </PBox>
      <PBox ml={4}>
        <Collapse in={expanded} timeout={200}>
          {participantSet.buyers?.map(participant => (
            <BuyerParticipantItem
              key={participant.id}
              party={party}
              plumBid={plumBid}
              viewForListingAgent
              participant={participant}
              selectedParticipant={selectedParticipant}
              showInBuyerOnboarding={showInBuyerOnboarding}
              setSelectParticipant={handleSelectParticipant}
            />
          ))}
          {participantSet.buyerAgents?.map(participant =>
            showInBuyerOnboarding ? null : (
              <BuyerParticipantItem
                key={participant.id}
                party={party}
                plumBid={plumBid}
                viewForListingAgent
                participant={participant}
                selectedParticipant={selectedParticipant}
                showInBuyerOnboarding={showInBuyerOnboarding}
                setSelectParticipant={handleSelectParticipant}
              />
            )
          )}
        </Collapse>
      </PBox>
    </>
  );
};
