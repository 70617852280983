import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { TERM_OF_USE, PRIVACY_POLICY } from 'routes';
import { PBox, RouterLink } from 'legos';
import { Footer } from 'components';
import { useSettingsState } from 'service/store';

export const TermsOfUse = () => {
  const { pathname, state } = useLocation();
  const { replace } = useHistory();
  const beckToHome = sessionStorage.getItem('inv');
  const [, dispatchSettings] = useSettingsState();

  useEffect(() => {
    if (pathname === TERM_OF_USE) {
      dispatchSettings({
        type: 'toggleAuthDrawer',
        payload: { isOpenDrawer: false },
      });
    }
  }, [dispatchSettings, pathname, state]);

  const replaceGoBack = () => {
    if (state === 'invite') {
      replace(beckToHome);
    }
  };

  return (
    <>
      <PBox display="flex" justifyContent="center">
        <PBox maxWidth="800px" m={2}>
          <h2 align="center">Terms of Use</h2>
          <h4>Effective Date: January 1, 2022</h4>
          <p align="justify">
            These Terms of Use (“Terms”) set forth a legally binding agreement between you and plumBid Inc. and its
            affiliates and subsidiaries (collectively, “plumBid,” “we,” “our,” or “us”), and govern your use of{' '}
            <a target="_blank" href="http://www.plumbid.com" rel="noreferrer">
              www.plumbid.com
            </a>{' '}
            along with any application, or other online service operated by plumBid (the “Site”), unless those websites,
            applications, or other online services have posted separate or additional terms of use.
          </p>
          <p>
            By using the Site, you agree to these Terms, and acknowledge that our collection, use, and sharing of your
            information and other activities is described in our{' '}
            <RouterLink to={PRIVACY_POLICY} onClick={replaceGoBack}>
              Privacy Policy
            </RouterLink>{' '}
            as updated from time to time.
          </p>
          <p>
            We may assign this agreement between you and plumBid to any party at any time without providing notice to
            you. You may not assign your rights under this agreement, by operation of law or otherwise, without our
            prior written consent.
          </p>
          <p>
            This Site is offered and available to users who are 18 years of age or older and reside in the United States
            or any of its territories or possessions, and by using this Site, you represent that you are of legal age to
            form a binding contract with us and meet all of the foregoing eligibility requirements.
          </p>
          <p>
            <b>
              It is important to read the Terms carefully as some of the provisions affect your legal rights and these
              Terms include an agreement to resolve any disputes through binding arbitration that includes a class
              action waiver, as well as important disclaimers, warranties and limitations on liability. If you do not
              agree to these Terms, please do not use the Site.
            </b>
          </p>
          <h4>Our Role</h4>
          <p>
            plumBid is not the seller, listing agent or buyer’s agent in any transaction. plumBid is the transaction
            platform in which all bids on our platform (“plumBids”) are conducted. plumBid is not a licensed real estate
            broker. plumBid does not confirm the accuracy or completeness of any information provided by any seller,
            listing agent, buyer, buyer’s agent or any other user of the Site including, without limitation, any
            information relating to the applicable property being sold or the creditworthiness of any buyer. All such
            information is provided on an as-is basis and plumBid expressly disclaims all express, implied or statutory
            representations or warranties relating to any information provided on the Site. You are encouraged to
            conduct your own diligence with respect to all information provided with respect to the applicable property
            and to consult with all relevant brokers, attorneys, and such other real estate professionals as may be
            necessary for you to evaluate the applicable property.
          </p>
          <p>
            All bid invitations are listed by the owner and/or their designated licensed real estate agents and their
            respective real estate brokerage firm.
          </p>
          <h2>Modifications</h2>
          <p>
            We may modify these Terms, in whole or in part, from time to time in our sole discretion, effective
            immediately upon posting modified Terms to the Site and, if you are an account holder, by communicating them
            to you. If you are an account holder, by not terminating your account (“Account”) within seven (7) days
            after our providing a notice of modifications to the Terms as described above or by continuing to use or
            access the Site or any of its services after modified Terms are posted to the Site, you agree to comply
            with, and be bound by, such modifications. Unless explicitly stated otherwise, any future offer made
            available to you on the Site that augments or otherwise enhances the current features of the Site shall be
            subject to these Terms.
          </p>
          <h4>Accounts</h4>
          <p>
            You may choose to or need to register for a plumBid Account to access some aspects of our services, such as
            if you want to make or participate in a bid in connection with an auction. Registering for an Account is
            optional, but if you do not register for an Account, you will not be able to access certain aspects of our
            services.
          </p>
          <p>By registering for an Account, you certify that:</p>
          <ul>
            <li>
              <p>You are 18 years of age or older;</p>
            </li>
            <li>
              <p>You have a valid U.S. Social Security number;</p>
            </li>
            <li>
              <p>
                Any information you provide to us, both when you register and in the future, is and will be true,
                accurate, current and complete;
              </p>
            </li>
            <li>
              <p>You are only registering an account for yourself;</p>
            </li>
            <li>
              <p>You will keep all information up-to-date; and</p>
            </li>
            <li>
              <p>
                You will create a unique password to create and access your plumBid Account that is unique to plumBid
                and not re-used across other, unaffiliated services.
              </p>
            </li>
          </ul>
          <p>
            You must not sell, transfer, or assign your account to anyone else. You must keep your password
            confidential, you must not share it and you may not allow anyone else to log into our Site as you. You are
            responsible for all activities that occur under your unique account. If you believe that your account is no
            longer secure, notify us immediately using the contact information below.
          </p>
          <p>
            If you do not maintain current, accurate, and complete information with us, we may have to suspend or
            terminate your Account.
          </p>
          <h4>Content</h4>
          <p>
            All text, graphic, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music,
            artwork, and computer code (collectively “Content”), including but not limited to the design, structure,
            selection, coordination, expression, and arrangement of such Content contained on the Site is owned and
            controlled by us and/or our licensors. The Content is protected by trade dress, copyright, patent,
            trademark, and other intellectual property and unfair competition laws. It is for your own personal and
            non-commercial use only, and we grant you a limited, non-exclusive, revocable, non-assignable, personal, and
            non-transferable license for that purpose, subject to your strict compliance with these Terms. Without
            limitation, this means that you may not sell, export, license, modify, copy, reverse engineer, distribute,
            or transmit the Content without our prior express written permission. Any unauthorized use of the Content
            will terminate the limited license granted by us. All other trademarks not owned by us that appear in
            connection with the Site are the property of their respective owners, who may or may not be affiliated with,
            connected to, or sponsored by us.
          </p>
          <h4>Your Conduct</h4>
          <p>
            By using the Site, you agree not to upload, post, email, or otherwise send or transmit any material that
            contains viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the
            functionality of any computer software or hardware or telecommunications equipment associated with the Site.
            You also agree not to interfere with the servers or networks connected to the Site or to violate any of the
            procedures, policies, or regulations of networks connected to the Site, the terms of which are incorporated
            herein. You also agree not to: (1) impersonate any other person while using the Site; (2) engage in any
            activity in connection with the Site that is fraudulent, unlawful, false or misleading, libelous,
            slanderous, defamatory, harmful, tortious, vulgar, invasive of another’s privacy, sexually explicit,
            offensive, obscene, profane, violent, threatening, harassing, abusive, or otherwise inappropriate; (3) use
            the Site for any unlawful purpose; (4) resell or export the software associated with the Site; (5) use the
            Site for any commercial or political purpose; (6) copy, derive, edit, translate, decompile, reverse
            engineer, modify, use, or reproduce any code or source relating to the Site; (7) interfere with the proper
            operation of any security measure used by the Site; (8) cause to appear any expanding button, banner
            advertisement, exit window, pop-up, pop-under, or anything else which minimizes, covers, or otherwise
            inhibits the full display of the Site; (9) or use any crawl, index, spider, click spam, macro programs,
            deep-link, page-scrape, robot, Internet agent or other automatic device, program, algorithm, or methodology,
            which does the same things, to use, access, copy, acquire information, generate impressions or clicks, input
            or store information, search or generate searches, or monitor any portion of the Site.
          </p>
          <h4>Third-Party Interactions</h4>
          <p>
            The Site may contain, as a convenience to you, content, links, and other information submitted by third
            parties over whom we have no control or responsibility. We have no obligation to monitor, control, or
            restrict the use of the Site, or third-party websites or services accessible via links available as part of
            the Site. These other websites or services are not under our control, and you acknowledge that (whether or
            not such websites or services are affiliated in any way with us) we are not responsible for the accuracy,
            copyright compliance, legality, decency, or any other aspect of the content of such websites or services.
            The inclusion of such a link does not imply endorsement of any website or service by us or any association
            with its operators.
          </p>
          <h4>Separate Terms and Conditions</h4>
          <p>
            From time to time in connection with your use of the Site or other interactions with us, it may be necessary
            for you to consent to policies or terms and conditions in addition to these Terms. You should read carefully
            any such additional terms and conditions. Unless otherwise expressly stated, any such terms will not vary or
            replace these Terms regarding any use of the Site.
          </p>
          <h4>Charges for Using the Site</h4>
          <p>
            We reserve the right at any time to charge fees for access to portions of the Services or the Services as a
            whole. In no event, however, will you be charged for access to the Services unless we obtain your prior
            agreement to pay such charges. If at any time we require a fee for portions of the Services that are
            currently free, we will provide you with advance notice of such fees. All new fees, if any, will be posted
            prominently on the Site and in other appropriate locations, and you shall pay all fees and charges incurred
            through your account at the rates in effect for the applicable billing period. You may also be subject to
            charges imposed by your wireless or other applicable carrier. Payment of such charges is solely your
            responsibility. You shall pay all applicable taxes relating to use of the Services.
          </p>
          <h4>Accuracy of Information</h4>
          <p>
            We make no guarantees as to the correctness, completeness, or accuracy of information on the Site. The Site
            may contain typographical errors, inaccuracies, or other errors or omissions. We disclaim any duty or
            obligation to update any such information, including news and press releases about us, and your reliance on
            information contained in these materials is at your own risk. We do not guarantee that the information we
            present as part of our Site, including information relating to creditworthiness or eligibility for loans or
            other financial products, is the same information that may be used or reviewed by a third party to assess
            your creditworthiness or eligibility for any particular product or service or for employment.
          </p>
          <h4>Use of Information Submitted</h4>
          <p>
            By uploading, posting, emailing, transmitting, or otherwise making available any information or material to
            us (“User-Provided Content”), you grant us a perpetual, irrevocable, royalty-free, transferable right and
            license to use, reproduce, display, perform, adapt, modify, delete in its entirety, publish, translate,
            create derivative works from, or distribute (or have distributed) such User-Provided Content and/or
            incorporate such User-Provided Content into any form, medium, or technology throughout the world without
            compensation or acknowledgement to you. You represent and warrant that you own or otherwise control all
            rights in and to any such User-Provided Content, and that our publication and use of your User-Provided
            Content will not infringe or violate the rights of any third party.
          </p>
          <p>
            When you choose to provide information to the Site, you agree to provide only true, accurate, current and
            complete information and to update it as necessary to maintain its truth and accuracy.
          </p>
          <p>
            You can remove your User-Provided Content by specifically deleting it. We will delete personal information
            in accordance with our{' '}
            <RouterLink to={PRIVACY_POLICY} onClick={replaceGoBack}>
              Privacy Policy
            </RouterLink>
            . plumBid is not responsible or liable for the removal or deletion of (or the failure to remove or delete)
            any of your User-Provided Content.
          </p>
          <h4>Infringement of Rights</h4>
          <p>
            You may not use our Site in any manner that infringes the rights of any third party. If you believe, in good
            faith, that any of our content infringes your copyrights, you may send a notice of infringement (“Notice”)
            under the federal Digital Millennium Copyright Act (“DMCA”) by email or regular mail to:
          </p>

          <p>plumBid, Inc.</p>
          <p>200 South Los Robles Avenue</p>
          <p>Suite 100</p>
          <p>Pasadena, California 91101</p>
          <a href="mailto:terms@plumBid.com">terms@plumBid.com</a>
          <p>
            To find out more about what you must include in the Notice and about the procedures we will follow,{' '}
            <a
              target="_blank"
              href="https://www.gpo.gov/fdsys/pkg/USCODE-2015-title17/html/USCODE-2015-title17-chap5-sec512.htm"
              rel="noreferrer"
            >
              click here to read the DMCA
            </a>
            .
          </p>
          <p>
            plumBid reserves the right to block or otherwise prohibit in our sole discretion any individual who
            repeatedly posts materials that are alleged to infringe the intellectual property rights of others.
          </p>
          <h4>Disclaimer</h4>
          <p>
            We provide the Site “as is” and without any representation, warranty, or condition, whether express,
            implied, or statutory. We specifically disclaim any implied warranties of title, merchantability, fitness
            for a particular purpose, and non-infringement. We assume no liability or responsibility for any errors or
            omissions with or on the Site; any failures, delays, or interruptions with the Site, or any connectivity to
            the site; any losses or damages arising from the use of the Site, including, without limitation, any damage
            to your mobile device; or any conduct by users of the Site. We reserve the right to withdraw or amend the
            Site, and any service or material we provide on the Site, in our sole discretion without notice. This
            includes our right to suspend or terminate a plumBid auction at any stage of the process. We will not be
            liable if for any reason all or any part of the Site is unavailable at any time or for any period, including
            if Site unavailability or error results in a plumBid not being registered or fulfilled. From time to time,
            we may restrict access to some parts of the Site, or the entire Site or suspend your access to the Site. Use
            of our Site is not a replacement for personal, professional advice or assistance regarding your finances,
            credit history or fixing your credit rating. You understand and acknowledge that the information provided
            through the Site is subject to change. You should check back frequently for updated information as to the
            properties and related information available.
          </p>
          <h4>plumBids</h4>
          <p>
            All plumBids constitute an irrevocable offer to buy pursuant to the terms set forth in the plumBid. In the
            event you are unable to perform in accordance with these Terms or if you fail to timely execute and deliver
            the purchase documents in accordance with the Terms, plumBid or the seller may declare the winning bidder to
            be in default of these Terms and consider other bids.
          </p>
          <h4>Transactions Subject to Finalization</h4>
          <p>
            All offers are subject to, and contingent upon, buyer and seller entering into a definitive purchase and
            sale agreement and buyer depositing the earnest money within the time periods set forth in the bid
            parameters for the applicable property. The definitive purchase and sale documentation shall be the sole
            responsibility of the buyer and seller and plumBid shall not be responsible for facilitating, providing,
            drafting, negotiating, or finalizing any purchase and sale agreements or any other transaction documents for
            any property. In addition to the foregoing, plumBid shall not be responsible for enforcing any such
            transaction documents or any terms or provisions therein. The transaction documents shall govern the
            relationship between the buyer and seller.
          </p>
          <h4>No Endorsements</h4>
          <p>
            The listing of any broker, escrow agent or any other third-party professional on the Site should not be
            viewed as an endorsement of any such person by plumBid and you should conduct your own diligence in
            determining whether any such third-party professional is qualified to be retained in connection with any
            transaction.
          </p>
          <h4>Limitations of Our Liability</h4>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL WE (OR ANY OF OUR AFFILIATES) BE LIABLE TO YOU FOR
            ANY LOSS, DAMAGE OR INJURY OF ANY KIND INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY,
            CONSEQUENTIAL, OR PUNITIVE LOSSES OR DAMAGES, OR DAMAGES FOR SYSTEM FAILURE OR MALFUNCTION OR LOSS OF
            PROFITS, DATA, USE, BUSINESS OR GOOD-WILL, ARISING OUT OF OR IN CONNECTION WITH (A) THE SITE, (B) THESE
            TERMS, (C) YOUR USE OF, OR INABILITY TO ACCESS OR USE, THE SITE OR ANY CONTENT AVAILABLE ON OR THROUGH THE
            SITE, OR (D) A THIRD PARTY’S UNAUTHORIZED ACCESS TO YOUR INFORMATION, EVEN IF SUCH DAMAGES WERE FORESEEABLE
            OR WE HAVE BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. TO THE MAXIMUM EXTENT
            PERMITTED BY LAW, OUR TOTAL LIABILITY FOR ANY AND ALL CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTORY, OR
            OTHER CLAIMS ARISING OUT OF OR RELATING TO (I) THIS AGREEMENT, (II) YOUR USE OR INABILITY TO USE THE SITE OR
            RELATED SERVICES, (III) YOUR USE OF ANY INFORMATION PROVIDED BY US OR ON THE SITE, (IV) ANY UNAUTHORIZED
            ACCESS OR ALTERATION OF YOUR TRANSMISSION OR DATA, OR (V) ANY ACTION OR INACTION OF ANY OTHER USER IS
            LIMITED TO THE SUM OF $100. THE LIMITATIONS SET FORTH IN THIS SECTION SHALL APPLY REGARDLESS OF THE FORM OF
            ACTION, WHETHER THE ASSERTED LIABILITY OR DAMAGES ARE BASED ON CONTRACT, INDEMNIFICATION, TORT, STRICT
            LIABILITY, STATUTE, OR ANY OTHER LEGAL OR EQUITABLE THEORY.
          </p>
          <p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
          <h4>Indemnity</h4>
          <p>
            You agree to indemnify and hold us, our affiliates, and each of our shareholders, directors, officers,
            employees, agents, and merchant partners harmless from and against any third-party claim or cause of action,
            including reasonable attorneys’ fees and court costs, arising, directly or indirectly, out of your use of
            the Site or your violation of any law or the rights of any third party.
          </p>
          <h4>Governing Law, Arbitration and Class Action Waiver</h4>
          <p>
            <b>
              THIS ARTICLE INCLUDES AN ARBITRATION AGREEMENT AND AN AGREEMENT THAT ALL CLAIMS WILL BE BROUGHT ONLY IN AN
              INDIVIDUAL CAPACITY (AND NOT AS A CLASS ACTION OR OTHER REPRESENTATIVE PROCEEDING). PLEASE READ IT
              CAREFULLY. YOU MAY OPT OUT OF THE ARBITRATION AGREEMENT BY FOLLOWING THE OPT OUT PROCEDURE DESCRIBED
              BELOW.
            </b>
          </p>
          <p>
            These Terms, their subject matter and their formation, including any arbitration proceeding outlined below,
            shall be governed by the laws of California, without application of its principles on conflict of laws that
            would require application of the laws of any other jurisdiction. Any arbitration proceedings will be
            conducted in Pasadena, California.
          </p>
          <p>
            <b style={{ textDecoration: 'underline' }}>Arbitration Agreement</b>: Any dispute, controversy, or claim
            (collectively, “Claim”) relating in any way to these Terms or your use of the Site, will be finally resolved
            by binding arbitration. This mandatory arbitration agreement applies equally to you and plumBid. However,
            this arbitration agreement does not (a) govern any Claim by plumBid for infringement of its Intellectual
            Property or access to the Site that is unauthorized or exceeds authorization granted in these Terms or (b)
            bar you from making use of applicable small claims court procedures in appropriate cases. If you are an
            individual, you may opt out of this arbitration agreement within thirty (30) days of your first access or
            use of the Site by following the procedure described below.
          </p>
          <p>
            You agree that the U.S. Federal Arbitration Act governs the interpretation and enforcement of this
            provision, and that you and plumBid are each waiving the right to a trial by jury or to participate in a
            class action. This arbitration provision will survive any termination of these Terms.
          </p>
          <p>
            The arbitration will be administered by JAMS under its Comprehensive Arbitration Rules and Procedures (the “
            <b>JAMS Rules</b>”) then in effect (those rules are deemed to be incorporated by reference into this
            section, and as of the date of these Terms).
          </p>
          <p>
            Payment for any and all reasonable JAMS filing, administrative and arbitrator fees will be in accordance
            with the JAMS Rules. If the value of your claim does not exceed $10,000, plumBid will pay for the reasonable
            filing, administrative and arbitrator fees associated with the arbitration, unless the arbitrator finds that
            either the substance of your claim or the relief sought was frivolous or brought for an improper purpose.
          </p>
          <p>
            The arbitrator, and not any federal, state, or local court, will have exclusive authority to resolve any
            dispute relating to the interpretation, applicability, unconscionability, arbitrability, enforceability, or
            formation of this arbitration agreement, including any claim that all or any part of this arbitration
            agreement is void or voidable. However, the preceding sentence will not apply to the “Class Action Waiver”
            section below.
          </p>
          <p>
            If you do not want to arbitrate disputes with plumBid and you are an individual, you may opt out of this
            arbitration agreement by sending an email to <a href="mailto:terms@plumBid.com">terms@plumBid.com</a> within
            thirty (30) days of the first date you access or use the Site.
          </p>
          <p>
            <b style={{ textDecoration: 'underline' }}>CLASS ACTION WAIVER</b>
            <b>
              : ANY CLAIM MUST BE BROUGHT IN THE RESPECTIVE PARTY’S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
              MEMBER IN ANY PURPORTED CLASS, COLLECTIVE, REPRESENTATIVE, MULTIPLE PLAINTIFF, OR SIMILAR PROCEEDING
              (“CLASS ACTION”). THE PARTIES EXPRESSLY WAIVE ANY ABILITY TO MAINTAIN ANY CLASS ACTION IN ANY FORUM. IF
              THE CLAIM IS SUBJECT TO ARBITRATION, THE ARBITRATOR WILL NOT HAVE AUTHORITY TO COMBINE OR AGGREGATE
              SIMILAR CLAIMS OR CONDUCT ANY CLASS ACTION NOR MAKE AN AWARD TO ANY PERSON OR ENTITY NOT A PARTY TO THE
              ARBITRATION. ANY CLAIM THAT ALL OR PART OF THIS CLASS ACTION WAIVER IS UNENFORCEABLE, UNCONSCIONABLE,
              VOID, OR VOIDABLE MAY BE DETERMINED ONLY BY A COURT OF COMPETENT JURISDICTION AND NOT BY AN ARBITRATOR.
              THE PARTIES UNDERSTAND THAT ANY RIGHT TO LITIGATE IN COURT, TO HAVE A JUDGE OR JURY DECIDE THEIR CASE, OR
              TO BE A PARTY TO A CLASS OR REPRESENTATIVE ACTION, IS WAIVED, AND THAT ANY CLAIMS MUST BE DECIDED
              INDIVIDUALLY, THROUGH ARBITRATION.
            </b>
          </p>
          <p>
            <b>
              If the above class action waiver is found to be unenforceable, then the entirety of the arbitration
              agreement in these Terms, if otherwise effective, will be null and void. The arbitrator may award
              declaratory or injunctive relief only in favor of the individual party seeking relief and only to the
              extent necessary to provide relief warranted by that party’s individual claim. If for any reason a claim
              proceeds in court rather than in arbitration, you and plumBid each waive any right to a jury trial.
            </b>
          </p>
          <h4>Waiver and Severability</h4>
          <p>
            Our failure to enforce any right or provision of these Terms will not be considered a waiver of those
            rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining
            provisions of these Terms will remain in effect.{' '}
          </p>
          <h4>Investigations; Cooperation with Law Enforcement</h4>
          <p>
            We reserve the right to investigate and prosecute any suspected breaches of these Terms and to otherwise
            monitor our services and the site electronically. We may disclose any information as necessary to satisfy
            any law, regulation, legal process, or governmental request.
          </p>
          <h4>Feedback</h4>
          <p>
            You may provide us feedback, comments, and suggestions for improvements to the Site (“Feedback”), which are
            separate from User-Provided Content. You hereby grant us a non-exclusive, worldwide, perpetual, irrevocable,
            fully-paid, royalty-free, sublicensable, and transferable license under any and all intellectual property
            rights that you own or control to use, copy, modify, create derivative works based upon, and otherwise
            exploit the Feedback for any purpose. plumBid is not and will not be obligated to compensate you for its use
            of Feedback.
          </p>
          <h4>Contact Us</h4>
          <p>If you have any questions about these Terms or the Site, please contact us at:</p>
          <p>plumBid, Inc.</p>
          <p>200 South Los Robles Avenue</p>
          <p>Suite 100</p>
          <p>Pasadena, California 91101</p>
          <a href="mailto:terms@plumBid.com">terms@plumBid.com</a>
          <p>Last Modified: January 1, 2022</p>
        </PBox>
      </PBox>
      <Footer />
    </>
  );
};
