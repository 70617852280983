import { Grid, Typography } from '@material-ui/core';
import { AboutUsTeam } from './AboutUsTeam';

import georgePennerImg from 'assets/images/george_penner_bw_web.png';
import galenBwImg from 'assets/images/galen_bw.png';
import mikeDeasyImg from 'assets/images/mike_deasy_bw.png';
import lynwenBwImg from 'assets/images/lynwen_bw.png';
import deniseTherieauBwImg from 'assets/images/denise_therieau_bw.png';
import seanMillerImg from 'assets/images/sean_miller.png';
import annieRosenbergerBwImg from 'assets/images/annie_rosenberger_bw.png';
import jamesGasselBwImg from 'assets/images/james_gassel.png';

const contactList = [
  {
    name: 'George Penner',
    position: 'Chief Executive Officer',
    picture: georgePennerImg,
    bio:
      'George Penner is the co-founder and CEO of plumBid. He brings over twenty years of experience in residential real estate, including; sales, development, investment, financing and management.',
    moreBioField1:
      'George is also the Chief Executive Officer of the residential brokerage firm Deasy Penner Podley (dpp) - responsible for both the day-to-day operations and the long-term strategic vision. With 250+ sales agents and support staff of 30, deasy penner podley represented 1500 transactions and sales in excess of $1.5 billion in 2021.',

    moreBioField2:
      "Prior to dpp, George was the COO of real estate brokerage firm, Mossler Deasy & Doe and coordinated the formulation of the nation's premiere ranch brokerage, Ranch Marketing Associates.",
    moreBioField3:
      'Before real estate, George was Senior Vice President and Director of Interactive Communications at Deutsch Inc. a dominant advertising agency that garnered significant attention during his tenure, winning Adweek s “Agency of the Year” for three consecutive years. Throughout his advertising career, he has played an extensive role in internet marketing, having created the first Honda.com, as well as having guided online marketing initiatives for companies such as Sony Entertainment and Expedia.com.',
    moreBioField4:
      'He has a BA in History from DePauw University and was a commissioned officer in the United States Naval Reserve.',
  },

  {
    name: 'Galen Loram',
    position: 'Chief Economist',
    picture: galenBwImg,
    bio:
      'Galen Loram serves as the chief economist behind plumBid. He joined in early 2016 and helped lead development of the plumBid optimizer. He represents the quantitative side of plumBid, devising the auction mechanism and helping translate the strategic direction into algorithms and troubleshooting the problems that arise.',
    moreBioField1:
      'Prior to plumBid, Galen has run a private tutoring and education business, where he has provided guidance and instruction to high school, college, and postgraduate students. At the The Capital Group Companies he was part of The Associates Program, where he had the opportunity to contribute across the company. Galen also launched and ran the startup, Inq. He brings a quantitative and analytic edge to helping ensure that plumBid help ensure that transactions meet the needs of buyers and sellers alike.',
    moreBioField2:
      'Galen received his B.S. from the California Institute of Technology (Caltech), where he studied economics, receiving the Frederic Hinrichs Jr. Award, the top award for a graduating senior.',
  },
  {
    name: 'James Gassel',
    position: 'President',
    picture: jamesGasselBwImg,
    bio:
      'James Gassel is President of plumBid. He is an enthusiastic business leader with 30+ years of experience in the oversight of company operations and strategy, including financing, corporate development and partnering, financial operations, program and portfolio management, legal, and HR.',
    moreBioField1:
      'Prior to joining plumBid, James led San Francisco based advertising agency, Evolution Bureau (EVB), as Partner and Chief Operating Officer for 12 years. EVB’s global client roster included Bed, Bath & Beyond, Jameson Irish Whiskey and Panda Express to name just a few.',

    moreBioField2:
      'Gassel’s extensive marketing experience has made him adept at building multinational brands that scale quickly and drive traffic. His tenure at Hal Riney & Partners as SVP and Group Director included the launch and consolidation of wireless carrier Sprint during a period of meteoric growth.  Before that, he played a pivotal role in launching powerhouse brands Microsoft Network (MSN) and luxury automaker, Infiniti.',
    moreBioField3:
      'Outside of work, Gassel is “crazy into his family.” He lives with his wife and three children in Marin County, California. Other passions include all things automotive – and biking, hiking or skiing in the great outdoors.',
  },
  {
    name: 'Mike Deasy',
    position: 'Co-Founder',
    picture: mikeDeasyImg,
    bio: 'Mike Deasy is Deasy Penner Podley s Chairman and its managing broker.',
    bioField2:
      'He is a Yale-educated urban planner-turned-realtor who has represented both buyers and sellers of some of the most architecturally significant residential properties in Southern California for the past 40+ years. An industry trailblazer, Mike helped pioneer the specialized market for architectural homes by leveraging his deep respect for art and architecture into a profitable approach to marketing properties.',
    moreBioField1:
      'Prior to DPP, Deasy was the President and Managing Broker of Mossler Deasy & Doe a residential real estate brokerage firm that pioneered the niche of representing architectural and historic properties in Southern California.',
    moreBioField2:
      'Born and raised in Los Angeles, Mike has long been inspired by the state s rich and groundbreaking tradition in residential architecture, currently serving as a board member of the Los Angeles Conservancy.',
    moreBioField3:
      'Almost immediately after founding deasy/penner in 2006, Mike, George and the rest of the team recognized the necessity for a formal New Development division dedicated to the representation of design-centric condominium projects in Los Angeles. Since its inception, it has become the definitive resource for developers successfully representing over 40 projects.',
    moreBioField4:
      'Mike Deasy is a past president of the Beverly Hills Board of Realtors, Director of the California Association of Realtors and was also president of the Combined Los Angeles Multiple Listing Service for four years. During that time, he helped initiate Listing Link, Caravan Express and other critical information systems and publications related to real estate.',
  },

  {
    name: 'Lynwen Hughes',
    position: 'Director',
    picture: lynwenBwImg,
    bio:
      "Lynwen has over 30 years of real estate experience encompassing listings, sales, management and dispute resolution. She oversees and is involved in the entire process for each plumBid, including determining appropriate plumBid properties, pricing, positioning, marketing, client relations and reviewing buyers' finances.",
  },

  {
    name: 'Denise Therieau',
    position: 'Director, Client Services',
    picture: deniseTherieauBwImg,
    bio:
      'Denise is the lead coordinator for plumBid. She begins with listing agents -- helping them prepare for their plumBid by ensuring that all inspections, seller documentation and disclosures are available to potential buyers. She liaises with the marketing department to develop communication materials, pre-event exposure and all property advertising to create maximum awareness and interest.',
    moreBioField1:
      "Once each plumBid is launched, Denise works with Buyers' agents to get their clients registered, qualified and educated about the entire process -- so they are ready for the big day. Denise works on plumBid's CRM implementation, as well as business development for this new transparent online real estate bidding platform.",
    moreBioField2:
      'Denise has 13 years of residential real estate sales experience, working at Coldwell Banker and Deasy Penner Podley.',
  },

  {
    name: 'Sean Miller',
    position: 'Marketing Director',
    picture: seanMillerImg,
    bio:
      'The success of a company’s products or services often relies on marketing. This is why we have Sean Miller at the helm of the marketing department, tasked with overseeing the development and implementation of marketing strategies, and with guiding the team that executes them. Sean understands their offering and their target audience, and sees to it that projects are completed on time and on budget. He leads the development of plumBid marketing plans working closely with clients, management, creative and content teams, and distribution partners, to create an effective and efficient marketing strategy focused on growing audiences for plumBid',
  },

  {
    name: 'Annie Rosenberger',
    position: 'Coordinator, Client Services',
    picture: annieRosenbergerBwImg,
    bio:
      'Annie works as a member of the plumBid team to help coordinate all efforts leading up to each plumBid auction. She helps to prepare our sellers, agents and all interest buyers about the plumBid property and the plumBid process. She uses her client services skills to communicate with sellers, buyers, agents, and any additional services we may need to successfully bring all qualified parties to the table.',
    moreBioField1:
      'Prior to coming to plumBid Annie worked for Parker & Morgan, a manufacturer of luxury linens. With a Masters in Marketing Communications and concentration on relationship marketing at Northwestern University, she organized and maintained systems for the marketing, sales and fulfillment of Parker & Morgan product through varied e-commerce sites.',
  },
];

export const AboutUs = () => {
  return (
    <Grid container justify="center">
      <Grid container item xs={12}>
        <Typography variant="h1" style={{ fontWeight: 500, fontSize: 34, paddingLeft: 96, paddingTop: 90 }}>
          Meet the team
        </Typography>
      </Grid>
      {contactList.map(item => (
        <AboutUsTeam contactList={item} />
      ))}
    </Grid>
  );
};
