import moment from 'moment';
import { useMutation } from '@apollo/client';
import { Grid, Typography, Box, ListItem } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';

import { Button, Divider, TextWithLabel } from 'legos';
import { PropertyThumbnail } from 'components';
import { formatNumberToUSD } from 'utils/formatters';
import { SANDBOX_START_PLUMBID_MUTATION } from 'service/graphql';
import { useDialog, useNotification, useResponsive } from 'utils/hooks';
import { DIALOG } from 'constant';
import { ADMIN_SANDBOX, SANDBOX_PLUMBID } from 'routes';
import { theme } from 'utils/theme';

export const SandboxPropertyItem = ({ property, refetch }) => {
  const { openDialog, closeDialog } = useDialog(DIALOG.ADD_PHANTOM_PLUM_BID);

  const responsive = useResponsive();

  const showNotification = useNotification();
  const [sandboxStartPlumBid, { loading }] = useMutation(SANDBOX_START_PLUMBID_MUTATION, {
    fetchPolicy: 'no-cache',
  });

  const { pathname } = useLocation();
  const history = useHistory();

  const openPlumBidPage = () => {
    sandboxStartPlumBid({
      variables: {
        input: {
          propertyId: property.id,
        },
      },
    })
      .then(res => {
        refetch();
        if (res.data?.sandboxStartPlumbid?.success) {
          history.push(`${SANDBOX_PLUMBID}?plumbid=${res.data?.sandboxStartPlumbid.plumbid.id}`);
        } else if (res.data?.sandboxStartPlumbid?.errors) {
          if (
            res.data.sandboxStartPlumbid.errors?.[0] === 'There is at least one live plumBid for this property already.'
          ) {
            history.push(`${SANDBOX_PLUMBID}?plumbid=${res.data?.sandboxStartPlumbid.plumbid.id}`);
          } else {
            showNotification({ error: res.data?.sandboxStartPlumbid?.errors });
          }
        }
      })
      .catch(error => {
        showNotification({ error });
        refetch();
      });
  };

  const handleOpenDialog = () => {
    openDialog({
      title: 'Edit Sample properties',
      okTitle: 'Save',
      property,
      onOk: () => {
        closeDialog();
        refetch();
      },
    });
  };

  const renderActiveMarkDateTime = () => {
    const status = {
      line1: '',
      line2: '',
    };
    return (
      <Box
        display="flex"
        pt={4}
        px={responsive.mobileScreen ? 0 : 2}
        flexDirection={responsive.padScreen ? 'row' : 'column'}
        alignItems="center"
        justifyContent={responsive.padScreen ? 'space-between' : null}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection={responsive.padScreen ? 'row' : 'column'}
        >
          <Box
            display="flex"
            flexDirection="column"
            pr={responsive.padScreen ? 2 : 0}
            style={responsive.padScreen ? { borderRight: `1px solid ${theme.palette.text.whisper}` } : null}
          >
            <Typography variant="body2" align="center" style={{ fontSize: '15px', textTransform: 'uppercase' }}>
              {moment().format('ddd')}
            </Typography>
            <Typography variant="body2" style={{ fontSize: '56px' }}>
              {moment().format('D')}
            </Typography>
          </Box>
          <Box pl={responsive.padScreen ? 2 : 0}>
            <Typography align="center" variant="subtitle2">
              {status.line1}
            </Typography>
            <Typography align="center" variant="subtitle2">
              {status.line2}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderSellerParameters = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TextWithLabel
            text={property.amountDown}
            label="Down Payment Importance Level"
            style={{ textTransform: 'none' }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextWithLabel
            text={property.idealEscrowLength}
            label="Ideal Escrow Length"
            style={{ textTransform: 'none' }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextWithLabel text={property.escrowPriority} label="Escrow Priority" style={{ textTransform: 'none' }} />
        </Grid>
        <Grid item xs={4}>
          <TextWithLabel
            text={property.inspectionPriority}
            label="Inspection Priority"
            style={{ textTransform: 'none' }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextWithLabel
            text={property.appraisalContingencyPriority}
            label="Appraisal Contingency Priority"
            style={{ textTransform: 'none' }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextWithLabel
            text={property.buyerWarrantyPriority}
            label="Buyer Warranty Priority"
            style={{ textTransform: 'none' }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextWithLabel text={property.leaseBackLength} label="Lease Back Length" style={{ textTransform: 'none' }} />
        </Grid>
        <Grid item xs={4}>
          <TextWithLabel
            text={property.loanContingencyPriority}
            label="Loan Contingency Priority"
            style={{ textTransform: 'none' }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextWithLabel
            text={property.sellerContingencyPriority}
            label="Seller Warranty Priority"
            style={{ textTransform: 'none' }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextWithLabel
            text={property.buyerContingencyPriority}
            label="Buyer Contingency Priority"
            style={{ textTransform: 'none' }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextWithLabel
            text={property.leaseBackImportance}
            label="Lease Back Importance"
            style={{ textTransform: 'none' }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <ListItem
      style={{
        paddingTop: responsive.padScreen ? 0 : 16,
        paddingBottom: 16,
        marginBottom: 24,
        position: 'relative',
        background: theme.palette.background.whiteSmoke,
      }}
    >
      <Grid container>
        <Grid
          item
          xs={responsive.padScreen ? 12 : 2}
          container
          justify="center"
          direction="column"
          style={{
            borderRight: responsive.padScreen ? null : `1px solid ${theme.palette.divider}`,
            paddingRight: responsive.padScreen ? null : 12,
          }}
        >
          {renderActiveMarkDateTime()}
        </Grid>
        <Grid item container xs={responsive.padScreen ? 12 : 10} style={{ paddingLeft: responsive.padScreen || 40 }}>
          <Grid item container xs={12} style={{ marginTop: 12 }}>
            <Grid item xs={responsive.mdScreen ? 12 : 2}>
              <PropertyThumbnail aspectRatio={1 / 1} src={property.previewImage} />
            </Grid>
            <Grid
              item
              zeroMinWidth
              xs={responsive.mdScreen ? 12 : 10}
              style={{ paddingLeft: responsive.mdScreen ? 0 : 16 }}
            >
              <Typography variant="h2" noWrap style={{ textTransform: 'uppercase', maxWidth: 600 }}>
                {property.title}
              </Typography>
              <Divider />
              <Box pt={2} pb={2}>
                <Grid item container justify="space-between" direction="row">
                  <Grid
                    item
                    xs={responsive.mobileScreen ? 12 : 5}
                    style={{ paddingBottom: responsive.mobileScreen ? 16 : 0 }}
                  >
                    <Typography
                      variant="h4"
                      style={{
                        textTransform: 'uppercase',
                        marginBottom: 4,
                      }}
                    >
                      Listing Price
                    </Typography>
                    <Typography variant="h6">
                      {formatNumberToUSD(property.listingPrice, {
                        hidePlusSign: true,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              {pathname === ADMIN_SANDBOX ? (
                <>
                  <Divider /> <Box my={4}>{renderSellerParameters()}</Box>
                </>
              ) : null}
              <Box pb={2} display="flex" flexDirection={responsive.mobileScreen ? 'column' : 'row'}>
                {pathname === ADMIN_SANDBOX ? (
                  <Box my={responsive.mobileScreen ? 2 : 0} style={{ width: '100%' }}>
                    <Button
                      variant="tertiary"
                      title="Edit Property"
                      icon="Edit"
                      onClick={() => handleOpenDialog()}
                      style={{ minWidth: 190 }}
                    />
                  </Box>
                ) : (
                  <Box mr={responsive.mobileScreen ? 0 : 1}>
                    <Button
                      title="Try Optimizer"
                      icon="Optimizer"
                      loading={loading}
                      onClick={() => openPlumBidPage()}
                      style={{ minWidth: 190 }}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};
