import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { DASHBOARD } from 'routes';
import { NavigationButton } from 'legos';
import { useSettingsState } from 'service/store';
import { PropertyThumbnailNameAddress } from 'components';
import { theme } from 'utils/theme';
import { useState } from 'react';
import { ResetTimeContainer } from './ResetTimeContainer';
import { useResponsive } from 'utils/hooks';

export const SubHeader = ({ plumbidData, plumbidLoading, nextTo, isSandbox, isWinner, nextToInactive }) => {
  const history = useHistory();
  const [, dispatchSettings] = useSettingsState();
  const [openTooltip, setOpenTooltip] = useState(false);
  const { mobileScreen } = useResponsive();
  const goBack = () => {
    if (nextTo === 'goBack') {
      if (isSandbox) {
        history.goBack();
      } else {
        if (nextToInactive) {
          history.push('/dashboard?t=3');
        } else {
          history.goBack();
        }
      }
      dispatchSettings({
        type: 'refreshScreen',
        payload: 'UserSandboxPage',
      });
    } else {
      dispatchSettings({
        type: 'refreshScreen',
        payload: 'DashboardScreen',
      });
      history.push(DASHBOARD);
    }
  };

  return (
    <Box
      px={{ xs: 2, md: 3, lg: 4, xl: 6 }}
      position="sticky"
      top={mobileScreen ? '-70px' : '66px'}
      zIndex={100}
      bgcolor="#FFFFFF"
    >
      <Grid container item alignItems="center" justify="space-between" spacing={2}>
        <Grid item xs={12} md={12} lg={4} container alignItems="center" justify="flex-start" spacing={3}>
          <Grid item xs="auto">
            <NavigationButton
              fullWidth={false}
              onClick={goBack}
              icon="LeftArrow"
              color={theme.palette.common.white}
              style={{
                backgroundColor: theme.palette.purple.light,
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <PropertyThumbnailNameAddress property={plumbidData.mls} isSandbox={isSandbox} />
          </Grid>
        </Grid>

        <Grid item xs={12} md={isSandbox && isWinner ? 7 : 8} lg={isSandbox && isWinner ? 4 : 5} container spacing={1}>
          <Grid item xs={6} container spacing={1}>
            <Grid item xs={8}>
              <Typography variant="h4">Total Registered Buyer Parties</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" style={{ textWeight: 800, color: theme.palette.purple.light }}>
                {isSandbox ? 'Sandbox' : plumbidData.buyerPartiesReady || '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3} container>
            <Grid item xs={8}>
              <Typography variant="h4">Total Bidders</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" style={{ textWeight: 800, color: theme.palette.purple.light }}>
                {plumbidData.totalBidders || '0'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3} container>
            <Grid item xs={8}>
              <Typography variant="h4">Total Bids</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" style={{ textWeight: 800, color: theme.palette.purple.light }}>
                {isSandbox ? plumbidData?.sbbidSet.length || '0' : plumbidData.totalBids || '0'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={isSandbox && isWinner ? 5 : 4}
          lg={isSandbox && isWinner ? 4 : 3}
          container
          id="tutorial__BidTime"
        >
          <ResetTimeContainer
            plumBid={plumbidData}
            plumbidLoading={plumbidLoading}
            isSandbox={isSandbox}
            isWinner={isWinner}
            openTooltip={openTooltip}
            setOpenTooltip={setOpenTooltip}
          />
        </Grid>
      </Grid>
      <Box pt={1}>
        <Divider />
      </Box>
    </Box>
  );
};
