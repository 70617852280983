import React, { useState } from 'react';

import { Box, Grid, Typography, Collapse } from '@material-ui/core';
import { LinkButton } from 'legos';
import { useResponsive } from 'utils/hooks';

import BuyerBenefitsPng from 'assets/images/BuyerBenefits.jpg';
import { theme } from 'utils/theme';

export const BuyerBenefits = () => {
  const { mdScreen, tabletScreen } = useResponsive();

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(prev => !prev);
  };
  return (
    <Grid
      container
      style={{
        position: 'relative',
        padding: '75px 0 80px 0',
        backgroundColor: theme.palette.background.benefits,
      }}
    >
      <Grid item xs={12} md={6} style={mdScreen ? { padding: 24 } : { paddingLeft: tabletScreen ? 40 : 88 }}>
        <img style={{ width: '100%', borderRadius: '38px' }} src={BuyerBenefitsPng} alt="" />
      </Grid>
      <Grid item container xs={12} md={6} justify="center">
        <Box px={{ xs: 3, md: 12 }} pt={tabletScreen ? 0 : 8}>
          <Typography variant="h1" style={{ fontWeight: 700, paddingBottom: 32 }}>
            Buyer benefits
          </Typography>

          <ul style={{ margin: 0, marginLeft: 16, padding: 0, color: theme.palette.text.transparent }}>
            <li>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '4px 0',
                  color: theme.palette.text.transparent,
                }}
              >
                Completely transparent and fair.
              </Typography>
            </li>
            <li>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '4px 0',
                  color: theme.palette.text.transparent,
                }}
              >
                Know exactly what the seller actually wants.
              </Typography>
            </li>
            <li>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '4px 0',
                  color: theme.palette.text.transparent,
                }}
              >
                Create the most competitive offer.
              </Typography>
            </li>
            <li>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '4px 0',
                  color: theme.palette.text.transparent,
                }}
              >
                Faster than ever.
              </Typography>
            </li>
          </ul>

          <Collapse in={checked} disableStrictModeCompat>
            <Typography
              variant="h3"
              style={{
                fontSize: 18,
                lineHeight: '27px',
                padding: '8px 0',
                color: theme.palette.text.transparent,
              }}
            >
              Buyers, aren’t you tired of not knowing what the seller really wants? plumBid is the fair and most
              transparent way to conduct a multiple offer.
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontSize: 18,
                lineHeight: '27px',
                padding: '8px 0',
                color: theme.palette.text.transparent,
              }}
            >
              No guesswork, with sellers first telling you what is important to them.
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontSize: 18,

                lineHeight: '27px',
                padding: '8px 0',
                color: theme.palette.text.transparent,
              }}
            >
              As the buyer, you utilize plumBid to adjust specifics of price, financing, escrow and inspection terms to
              create the most competitive offer - all online.
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontSize: 18,

                lineHeight: '27px',
                padding: '8px 0',
                color: theme.palette.text.transparent,
              }}
            >
              Because you can see others’ plumBids, you’re assured you are neither overbidding nor getting preempted by
              another buyer whom you would have beaten. And if you’re not an all-cash buyer, plumBid delivers a
              foolproof way to ensure that your offer is considered fairly.
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontSize: 18,

                lineHeight: '27px',
                padding: '8px 0',
                color: theme.palette.text.transparent,
              }}
            >
              If you are outbid by another buyer, you can bid again - if you choose. All in a compressed time period, so
              no waiting for days. Escrow is opened and you’re on your way to buying a great new home.
            </Typography>
          </Collapse>
          <LinkButton
            onClick={handleChange}
            style={{
              textDecoration: 'underline',
              fontSize: 18,
              fontWeight: 700,
              color: theme.palette.primary.main,
              marginTop: '30px',
            }}
          >
            {checked ? 'Show less' : 'Show more'}
          </LinkButton>
        </Box>
      </Grid>
    </Grid>
  );
};
