import { Table, TableBody, Typography, TableContainer } from '@material-ui/core';

import { HistoryTableBody } from './HistoryTableBody/HistoryTableBody';
import HistoryTableHead from './HistoryTableHead';

export const HistoryTable = ({ bids, myRole, myPartyId, sandboxKey, isSandbox }) => {
  return bids.length < 1 ? (
    <Typography variant="h4">No bids</Typography>
  ) : (
    <TableContainer>
      <Table stickyHeader aria-label="bids table">
        <HistoryTableHead />
        <TableBody>
          {bids.map((bid, index) => (
            <HistoryTableBody
              key={bid.id}
              bid={bid}
              myRole={myRole}
              isInitBid={bids.length - 1 === index}
              myPartyId={myPartyId}
              sandboxKey={sandboxKey}
              isSandbox={isSandbox}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
