import React, { useMemo, useState, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';

import { SubHeader } from '../PlumBid/SubHeader';
import { SANDBOX_PLUMBID } from 'routes';
import { WinnerItem } from '.';
import { ShowHistory } from '../PlumBid/ShowHistory';
import { useSettingsState } from 'service/store';
import { LocalStorage } from 'utils/localStorage';
import { HistoryBids, Footer } from 'components';
import { ROLES, PLUMBID_STATUS, DIALOG } from 'constant';
import { PropertyDescriptionCard } from '../PlumBid/PropertyDescriptionCard';
import { usePlumbid, useDialog, usePageLoading, useTimeLeft, useResponsive } from 'utils/hooks';
import { RedirectPage } from 'components/RedirectPage';

export const BidHistoryContent = () => {
  const responsive = useResponsive();

  const history = useHistory();
  const [, dispatchSettings] = useSettingsState();
  const location = useLocation();

  const sandboxKey = LocalStorage.getSandboxKey();
  const [showHistory, setShowHistory] = useState(false);

  const { plumbid, t } = queryString.parse(location.search);
  const isSandbox = location.pathname === SANDBOX_PLUMBID;

  const { openDialog: openGetSandboxFinishDialog, closeDialog: closeGetSandboxFinishDialog } = useDialog(
    DIALOG.OK_CANCEL
  );

  const { plumbidData, plumBidError, bidsHistory, bidsHistoryLoading, plumbidLoading } = usePlumbid(isSandbox, {
    variables: { id: plumbid },
  });

  usePageLoading(plumbidLoading, 'BidHistoryContent');
  useTimeLeft(plumbidData);

  const isWinnerSandbox = useMemo(() => {
    if (plumbidData?.status === PLUMBID_STATUS.FINISHED) {
      if (plumbidData?.sbbidSet?.length > 0) {
        if (
          plumbidData?.sbbidSet?.reduce((prev, cur) => {
            if (cur.plumBidAmount > prev.plumBidAmount) {
              return cur;
            }
            return prev;
          })?.owner.user.key === LocalStorage.getSandboxKey()
        ) {
          return true;
        }
      }
      return false;
    }
    return false;
  }, [plumbidData]);

  const PropertyDescriptionMemo = useMemo(
    () => (plumbidData ? <PropertyDescriptionCard property={plumbidData.mls} /> : null),
    [plumbidData]
  );

  const HistoryBidsMemo = useMemo(
    () =>
      plumbidData ? (
        <HistoryBids
          bids={bidsHistory}
          bidsHistoryLoading={bidsHistoryLoading}
          isMeBuyer={plumbidData?.myInfoInPlumBid.role === ROLES.BUYER}
          isSandbox={isSandbox}
          sandboxKey={sandboxKey}
          isWinner={isSandbox ? isWinnerSandbox : plumbidData?.myInfoInPlumBid.isWinner}
          myRole={plumbidData?.myInfoInPlumBid?.role}
          myPartyId={plumbidData?.myInfoInPlumBid?.myParty?.id}
        />
      ) : null,
    [plumbidData, bidsHistory, bidsHistoryLoading, isSandbox, sandboxKey, isWinnerSandbox]
  );

  const isMyPartyBidHighest = isSandbox
    ? bidsHistory[0]?.owner?.user?.key === sandboxKey
    : bidsHistory[0]?.partyId === +plumbidData?.myInfoInPlumBid?.myParty?.id;

  const nextTo = 'goBack';

  useEffect(() => {
    if (isSandbox && plumbidData?.status === PLUMBID_STATUS.FINISHED) {
      openGetSandboxFinishDialog({
        onOk: () => {
          history.goBack();
          dispatchSettings({
            type: 'refreshScreen',
            payload: 'UserSandboxPage',
          });
          closeGetSandboxFinishDialog();
        },
        okTitle: 'Confirm',
        title: 'plumBid finished',
        text: 'This plumBid has expired, please choose another one!',
      });
    }
  }, [
    history,
    isSandbox,
    dispatchSettings,
    plumbidData?.status,
    openGetSandboxFinishDialog,
    closeGetSandboxFinishDialog,
  ]);

  return plumbidData ? (
    <>
      <SubHeader
        plumbidData={plumbidData}
        nextTo={nextTo}
        plumbidLoading={plumbidLoading}
        isSandbox={isSandbox}
        isWinner={isWinnerSandbox}
        isBidHistory
        nextToInactive={t}
      />
      <Box py={2} px={{ xs: 1, md: 3, lg: 4, xl: 6 }}>
        {plumbidData?.status === PLUMBID_STATUS.FINISHED && bidsHistory[0] && (
          <>
            <WinnerItem
              bids={bidsHistory}
              myRole={plumbidData?.myInfoInPlumBid.role}
              bid={bidsHistory[0]}
              isMyBidHighest={isMyPartyBidHighest}
              myPartyId={plumbidData?.myInfoInPlumBid?.myParty?.id}
            />
            {!showHistory && <ShowHistory setShowHistory={setShowHistory} />}
          </>
        )}
        <Grid container spacing={responsive.mdScreen ? 0 : 2} justify="flex-start">
          <Grid item container xs={12} style={{ paddingTop: responsive.mdScreen && 8 }}>
            {(plumbidData?.status !== PLUMBID_STATUS.FINISHED || showHistory) && HistoryBidsMemo}
          </Grid>

          <Grid item container xs={12} style={{ paddingTop: responsive.mdScreen && 8 }}>
            {PropertyDescriptionMemo}
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  ) : plumBidError && !plumbidData ? (
    <RedirectPage />
  ) : null;
};
