import { NEW_PARTY_STATUS, PLUMBID_STATUS, ROLES } from 'constant';
import state from 'service/graphql/state';

const getLastBidIdKey = (plumBidId, isSandbox) => {
  return `bc${isSandbox ? 'sb' : ''}${plumBidId}`;
};

const getLastBidPartyIdKey = (plumBidId, isSandbox) => {
  return `bc${isSandbox ? 'sb' : ''}${plumBidId}p`;
};

export const setGlobalPopup = (plumBid, myInfoInPlumBid, isSandbox) => {
  const globalPlumBidPopup = state.globalPlumBidPopupVar();

  // Back up dialog
  if (
    plumBid.status === PLUMBID_STATUS.FINISHED &&
    myInfoInPlumBid.myPopupStatus?.backUpPosition === true &&
    myInfoInPlumBid.role === ROLES.BUYER &&
    myInfoInPlumBid.myPartyStatus === NEW_PARTY_STATUS.COMPLETED
  ) {
    state.globalPlumBidPopupVar({
      ...globalPlumBidPopup,
      [plumBid.id]: {
        ...globalPlumBidPopup[plumBid.id],
        popupBackupPositionNeedToShow: true,
      },
    });
  }

  // Finish plumBid data has changed dialog
  if (
    (plumBid.status === PLUMBID_STATUS.LIVE || plumBid.status === PLUMBID_STATUS.UPCOMING) &&
    myInfoInPlumBid.myPopupStatus?.finishDateHasChanged === true &&
    (myInfoInPlumBid.role === ROLES.BUYER || myInfoInPlumBid.role === ROLES.SELLER)
  ) {
    state.globalPlumBidPopupVar({
      ...globalPlumBidPopup,
      [plumBid.id]: {
        ...globalPlumBidPopup[plumBid.id],
        popupFinishDateHasChangedNeedToShow: true,
      },
    });
  }

  // plumBid started dialog
  if (
    plumBid.status === PLUMBID_STATUS.LIVE &&
    myInfoInPlumBid.myPopupStatus?.plumBidStarted === true &&
    (myInfoInPlumBid.role === ROLES.BUYER || myInfoInPlumBid.role === ROLES.BUYER_AGENT
      ? myInfoInPlumBid.myPartyStatus === NEW_PARTY_STATUS.COMPLETED
      : true)
  ) {
    state.globalPlumBidPopupVar({
      ...globalPlumBidPopup,
      [plumBid.id]: {
        ...globalPlumBidPopup[plumBid.id],
        popupPlumBidJustStartedNeedToShow: !!myInfoInPlumBid.myPopupStatus?.plumBidStarted,
        participantId: myInfoInPlumBid.participantId,
      },
    });
  }

  // New bid recieved dialog
  if (
    plumBid.status === PLUMBID_STATUS.LIVE &&
    (myInfoInPlumBid.role === ROLES.BUYER || myInfoInPlumBid.role === ROLES.BUYER_AGENT
      ? myInfoInPlumBid.myPartyStatus === NEW_PARTY_STATUS.COMPLETED
      : true)
  ) {
    let popupNewBidReceivedNeedToShow = myInfoInPlumBid.myPopupStatus?.plumBidStarted
      ? false
      : !!myInfoInPlumBid.myPopupStatus?.newBidReceived;

    const newLastBidId = plumBid?.lastBid?.id;
    const newLastBidPartyId = plumBid?.lastBid?.partyId;

    const oldLastBidId =
      globalPlumBidPopup?.[plumBid.id]?.lastBidId || localStorage.getItem(getLastBidIdKey(plumBid.id, isSandbox));

    const oldLastBidPartyId =
      globalPlumBidPopup?.[plumBid.id]?.lastBidPartyId ||
      localStorage.getItem(getLastBidPartyIdKey(plumBid.id, isSandbox));

    if (oldLastBidId && newLastBidId) {
      popupNewBidReceivedNeedToShow = `${oldLastBidId}` !== `${newLastBidId}`;
    }

    if (myInfoInPlumBid.myParty?.id === `${plumBid.lastBid?.partyId}`) {
      popupNewBidReceivedNeedToShow = false;
    }

    if (newLastBidId) {
      localStorage.setItem(getLastBidIdKey(plumBid.id, isSandbox), newLastBidId);
      localStorage.setItem(getLastBidPartyIdKey(plumBid.id, isSandbox), newLastBidPartyId);
    }

    state.globalPlumBidPopupVar({
      ...globalPlumBidPopup,
      [plumBid.id]: {
        ...globalPlumBidPopup[plumBid.id],
        participantId: myInfoInPlumBid.participantId,
        popupNewBidReceivedNeedToShow,
        myPartyBidWasHighest: `${oldLastBidPartyId}` === `${myInfoInPlumBid.myParty?.id}`,
        ...(newLastBidId ? { lastBidId: newLastBidId, lastBidPartyId: newLastBidPartyId } : null),
      },
    });
  }
};
