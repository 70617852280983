import React, { useState, useEffect } from 'react';
import { Grid, Menu, MenuItem, Collapse, Box, Typography } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { LinkButton } from 'legos';
import { removeSession } from 'service/auth';
import { useAuthState, useSettingsState } from 'service/store';
import { HOME, SETTINGS } from 'routes';
import state from 'service/graphql/state';
import { useResponsive } from 'utils/hooks';

const StyledMenu = withStyles(theme => ({
  paper: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 0,
    minWidth: 130,
  },
}))(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorReference="anchorEl"
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    {...props}
  />
));

export const AuthNav = () => {
  const responsive = useResponsive();
  const [{ me }, dispatchAuth] = useAuthState();
  const { pathname } = useLocation();
  const [checked, setChecked] = React.useState(false);
  const history = useHistory();

  const [{ isOpenDrawer, invite }, dispatchSettings] = useSettingsState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = event => {
    if (!me) {
      dispatchSettings({
        type: 'toggleAuthDrawer',
        payload: {
          isOpenDrawer: !isOpenDrawer,
          drawerScreen: invite?.userExists
            ? 'SignInFromInvitationScreen'
            : pathname === '/invite' && !!invite
            ? 'SignUpFromInvitationScreen'
            : 'SignInScreen',
          drawerScreenGoBack: pathname === '/invite' && !!invite ? 'SignInFromInvitationScreen' : 'SignInScreen',
        },
      });
    } else {
      setChecked(!checked);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleLogout = () => {
    handleMenuClose();
    removeSession();
    dispatchAuth({ type: 'logout' });
    history.replace(HOME);
  };

  const handleSettings = () => {
    handleMenuClose();
    history.push(SETTINGS);
  };

  useEffect(() => {
    if (open) {
      state.tutorialSkipRunVar(false);
    }
  }, [open]);

  return (
    <Grid item xs={12} lg={2}>
      <Box style={responsive.padScreen ? { paddingLeft: 48, textAlign: 'start' } : { textAlign: 'end' }}>
        <LinkButton
          icon={me ? 'UserLogged' : 'Login'}
          iconSize={24}
          iconEnd
          variant="body2"
          title={me ? me.fullName : 'Login'}
          onClick={handleClick}
          style={{
            fontSize: 14,
            fontWeight: 400,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '100%',
          }}
        />
      </Box>
      {responsive.padScreen ? (
        <Box pt={2} display="flex" style={{ overflow: 'hidden' }}>
          <div style={{ width: '100%' }}>
            <Collapse in={checked}>
              <Box
                onClick={handleSettings}
                style={{
                  textAlign: 'start',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  variant="body1"
                  color="textPrimary"
                  style={{
                    padding: '16px 0 16px 62px ',
                    borderTop: '1px solid #E6E6E6',
                  }}
                >
                  Profile
                </Typography>
              </Box>
              <Box
                onClick={handleLogout}
                style={{
                  textAlign: 'start',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  variant="body1"
                  color="textPrimary"
                  style={{
                    width: '100%',
                    padding: '16px 0 16px 62px ',
                    borderTop: '1px solid #E6E6E6',
                  }}
                >
                  Logout
                </Typography>
              </Box>
            </Collapse>
          </div>
        </Box>
      ) : (
        <StyledMenu id="profile-menu" anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
          <MenuItem onClick={handleSettings} style={{ height: 50 }}>
            Profile
          </MenuItem>
          <MenuItem onClick={handleLogout} style={{ height: 50 }}>
            Logout
          </MenuItem>
        </StyledMenu>
      )}
    </Grid>
  );
};
