import { useState } from 'react';

import { Box, Grid, Typography, Collapse } from '@material-ui/core';
import { LinkButton } from 'legos';
import { useResponsive } from 'utils/hooks';

import SellerBenefitsPng from 'assets/images/SellerBenefits.jpg';
import { theme } from 'utils/theme';

export const SellerBenefits = () => {
  const { mdScreen, tabletScreen } = useResponsive();

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(prev => !prev);
  };
  return (
    <Grid
      container
      style={{
        position: 'relative',
        padding: '75px 0 80px 0',
        backgroundColor: theme.palette.background.benefits,
      }}
    >
      <Grid item xs={12} md={6} style={mdScreen ? { padding: 24 } : { paddingLeft: tabletScreen ? 40 : 88 }}>
        <img style={{ width: '100%', borderRadius: '38px' }} src={SellerBenefitsPng} alt="" />
      </Grid>
      <Grid item container xs={12} md={6} justify="center">
        <Box px={{ xs: 3, md: 12 }} pt={tabletScreen ? 0 : 8}>
          <Typography variant="h1" style={{ fontWeight: 700, paddingBottom: 32 }}>
            Seller benefits
          </Typography>
          <ul style={{ margin: 0, marginLeft: 16, padding: 0, color: theme.palette.text.transparent }}>
            <li>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '4px 0',
                  color: theme.palette.text.transparent,
                }}
              >
                Proven to get you a higher price for your home.
              </Typography>
            </li>
            <li>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '4px 0',
                  color: theme.palette.text.transparent,
                }}
              >
                Secure the terms you want.
              </Typography>
            </li>
            <li>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '4px 0',
                  color: theme.palette.text.transparent,
                }}
              >
                Clearly communicate your preferences to potential buyers.
              </Typography>
            </li>
            <li>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '4px 0',
                  color: theme.palette.text.transparent,
                }}
              >
                All online and faster than ever.
              </Typography>
            </li>
          </ul>
          <Collapse in={checked} disableStrictModeCompat>
            <Typography
              variant="h3"
              style={{
                fontSize: 18,
                lineHeight: '27px',
                padding: '8px 0',
                color: theme.palette.text.transparent,
              }}
            >
              plumBid is proven to get you both the best price and terms for your property.
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontSize: 18,
                lineHeight: '27px',
                padding: '8px 0',
                color: theme.palette.text.transparent,
              }}
            >
              Here’s how it works: Based on your priorities and the current economic conditions, such as escrow length,
              amount of cash down and the release of an inspection contingency - plumBid configures and generates
              incentive values for each of those items, based on your priorities and current economic conditions.
            </Typography>

            <Typography
              variant="h3"
              style={{
                fontSize: 18,

                lineHeight: '27px',
                padding: '8px 0',
                color: theme.palette.text.transparent,
              }}
            >
              Since each buyer knows what matters to you, they can put together the most attractive and competitive bid.
              If they are outbid by another buyer, they can bid again. All in an efficient time period, so no waiting
              for days. Escrow is opened and you’re on your way to a successful closing.
            </Typography>
          </Collapse>
          <LinkButton
            onClick={handleChange}
            style={{
              textDecoration: 'underline',
              fontSize: 18,
              fontWeight: 700,
              color: theme.palette.primary.main,
              marginTop: '30px',
            }}
          >
            {checked ? 'Show less' : 'Show more'}
          </LinkButton>
        </Box>
      </Grid>
    </Grid>
  );
};
