import { useState } from 'react';
import { Box, Grid, Typography, Collapse } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { BUYERS, ABOUT_US } from 'routes';
import AboutPlumBidPng from 'assets/images/AboutPlumBid.png';

import { RouterLink, LinkButton } from 'legos';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const AboutPlumBid = () => {
  const { mdScreen, tabletScreen } = useResponsive();

  const { pathname } = useLocation();
  const pathnameBuyer = pathname === BUYERS;
  const pathnameAboutUs = pathname === ABOUT_US;

  const [checked, setChecked] = useState(tabletScreen ? false : true);

  const handleChange = () => {
    setChecked(prev => !prev);
  };

  return (
    <Grid
      container
      style={{
        padding: mdScreen ? '30px 0' : '75px 0 80px 0',
        backgroundColor:
          pathnameBuyer || pathnameAboutUs ? theme.palette.background.benefits : theme.palette.background.card,
      }}
    >
      {!pathnameBuyer && (
        <Grid
          item
          xs={12}
          md={6}
          style={mdScreen ? { padding: 24 } : { paddingLeft: tabletScreen ? 0 : mdScreen ? 0 : 88 }}
        >
          <img style={{ width: '100%' }} src={AboutPlumBidPng} alt="" />
        </Grid>
      )}
      <Grid item container xs={12} md={6} justify="center" alignItems="flex-start">
        <Box px={{ xs: 3, md: 10 }} pt={tabletScreen ? 0 : 10}>
          <Typography variant="h1" style={{ fontWeight: 700 }}>
            plumBid – invented by experts
          </Typography>
          <Typography
            variant="h3"
            style={{
              fontSize: 18,
              lineHeight: '27px',
              padding: '26px 0',
              color: theme.palette.text.transparent,
            }}
          >
            While plumBid is an innovative concept, its founders leverage both an expertise in finance, as well as a 50+
            year background in residential real estate. From Caltech educated economists, to real estate brokerage
            visionaries that have represented over ten thousand homes and billions of dollars in transactions.
          </Typography>
          <Collapse in={checked} disableStrictModeCompat>
            <Typography
              variant="h3"
              style={{
                fontSize: 18,
                lineHeight: '27px',
                color: theme.palette.text.transparent,
              }}
            >
              In 2020, their firm was awarded “Most Innovative Brokerage” for their invention of plumBid by LeadingRE -
              a global network of 565 real estate companies representing 130,000 agents, across 70+ countries.
              {pathnameAboutUs || (
                <RouterLink
                  to={ABOUT_US}
                  style={{
                    fontSize: 18,
                    paddingLeft: 4,
                    fontWeight: 700,
                    color: theme.palette.primary.main,
                  }}
                >
                  Learn more
                </RouterLink>
              )}
            </Typography>
          </Collapse>
          {tabletScreen && (
            <LinkButton
              onClick={handleChange}
              style={{
                textDecoration: 'underline',
                fontSize: 18,
                fontWeight: 700,
                color: theme.palette.primary.main,
                marginTop: '30px',
              }}
            >
              {checked ? 'Show less' : 'Show more'}
            </LinkButton>
          )}
        </Box>
      </Grid>
      {pathnameBuyer && (
        <Grid
          item
          xs={12}
          md={6}
          style={{ paddingRight: tabletScreen ? 40 : mdScreen ? 20 : 88, paddingLeft: mdScreen ? 20 : 0 }}
        >
          <img style={{ width: '100%' }} src={AboutPlumBidPng} alt="" />
        </Grid>
      )}
    </Grid>
  );
};
