import React, { useMemo } from 'react';
import { Grid, Box, Typography, Tooltip, Zoom } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { Button } from 'legos';
import { UploadingBarFooter } from './UploadingBarFooter';
import { theme } from 'utils/theme';
import { formatUserFullName } from 'utils';
import { DASHBOARD } from 'routes';

const CustomToolTip = withStyles(() => ({
  tooltip: {
    padding: 6,
    fontSize: 12,
  },
}))(Tooltip);

export const WizardFooter = ({
  onCancel,
  onNextStep,
  onPrevStep,
  onFinishStep,
  onFirstStep,
  isFirstStep,
  isLastStep,
  isNextDisabled,
  isFinishDisabled,
  countTotalFiles,
  processingInfo,
  nextLoading,
  currentStep,
  responsive,
  warning,
  mlsId,
  displayOnlyStep5,
  isOfferChanged,
  history,
  saveButtonLoading,
  isPlumbidFinished,
  isAdmin,
  onExit,
  plumBid,
  isAdminInTheWizard,
  moveToNextReviewOfferInfo,
}) => {
  const isDisabled = useMemo(() => {
    if (isLastStep) {
      if (moveToNextReviewOfferInfo?.countPartyToReview) {
        return false;
      }
      return !mlsId ? false : countTotalFiles === 0 ? true : isFinishDisabled;
    }

    return isNextDisabled;
  }, [
    countTotalFiles,
    isFinishDisabled,
    isLastStep,
    isNextDisabled,
    mlsId,
    moveToNextReviewOfferInfo?.countPartyToReview,
  ]);

  const showUploading = processingInfo.isRunProcessing && currentStep < 5 && !isFirstStep;
  const footerInfo = (currentStep === 3 && !mlsId) || currentStep >= 5;
  const showLAInfo = (isAdminInTheWizard || isAdmin) && (!showUploading || (showUploading && !responsive.padScreen));
  const continueButtonIcon =
    currentStep >= 5 ? (isDisabled ? null : warning === true ? 'WarningCircle' : 'CircleCheck') : 'RightArrow';
  const isHighFooter =
    (footerInfo || showUploading || (showUploading && isAdminInTheWizard) || showLAInfo) && responsive.mdScreen;
  const nextButtonTitle = useMemo(() => {
    if (currentStep >= 5) {
      if (moveToNextReviewOfferInfo?.countPartyToReview > 1 && plumBid?.buyerParty.length > 1) {
        return 'Review Next Offer';
      }
      return !mlsId ? 'Add your property ID' : 'Finish';
    }
    return 'Continue';
  }, [currentStep, mlsId, moveToNextReviewOfferInfo?.countPartyToReview, plumBid?.buyerParty.length]);

  const renderDisplayOnlyStep5Content = () => {
    return (
      <Grid container spacing={2} justify={responsive.mdScreen ? 'center' : 'flex-end'} alignItems="center">
        <Grid
          item
          xs={responsive.mobileScreen ? 12 : 'auto'}
          style={{ textAlign: responsive.mdScreen ? 'center' : 'end' }}
        >
          {isPlumbidFinished ? (
            <Typography>
              The selected plumBid is over or will not be conducted, please create a new plumBid to add offers
            </Typography>
          ) : warning ? (
            <Typography>Please Review the Highlighted Fields in Offers Marked with ? Sign</Typography>
          ) : (
            (isOfferChanged || (!warning && !saveButtonLoading)) && (
              <Box mx={2}>
                <Typography>
                  For all participants with e-mail the invitation will be sent after clicking "Save and Go Back" button
                </Typography>
                <Typography>
                  For participants without e-mail, you can copy the invitation link and send it via messenger
                </Typography>
              </Box>
            )
          )}
        </Grid>
        <Grid item xs={responsive.mobileScreen ? 12 : 'auto'}>
          <Box display="flex" justifyContent={responsive.mdScreen ? 'center' : 'flex-end'}>
            {!isPlumbidFinished && (
              <Button
                variant="secondary"
                marginLeft={2}
                marginRight={2}
                title="Go to Dashboard"
                align="center"
                onClick={() => history.push(DASHBOARD)}
                style={{
                  minWidth: 'fit-content',
                }}
              />
            )}
            <Box pl={1}>
              <Button
                color="white"
                align="center"
                title={saveButtonLoading ? ' ' : isPlumbidFinished ? 'Go Back' : 'Save and Go Back'}
                onClick={isOfferChanged || !isPlumbidFinished ? onFinishStep : history.goBack}
                disabled={saveButtonLoading}
                loading={saveButtonLoading}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  minWidth: '150px',
                  border: `2px solid ${theme.palette.primary.main}`,
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderListingAgentInfo = () => (
    <Box display="flex" flexDirection="column" alignItems="start">
      <Typography variant="h5">Listing Agent:</Typography>
      <Typography variant="h5">{formatUserFullName(plumBid?.owner)}</Typography>
      <Typography variant="h5" style={{ color: theme.palette.text.stone }}>
        email: {plumBid?.owner.email}
      </Typography>
      <Typography variant="h5" style={{ color: theme.palette.text.stone }}>
        phone: {plumBid?.owner.phone}
      </Typography>
    </Box>
  );

  const renderFooterInfo = () => (
    <Box mx={2}>
      {currentStep === 3 && !mlsId && (
        <Typography>You will be able to invite sellers when you add a property MLS ID on the first step.</Typography>
      )}
      {currentStep >= 5 &&
        (countTotalFiles === 0 ? (
          <Typography>To continue plumBid setup please add at least one buyer offer </Typography>
        ) : (
          <>
            <Typography>
              For all participants with e-mail the invitation will be sent after clicking "Finish" button
            </Typography>
            <Typography>
              For participants without e-mail, you can copy the invitation link and send it via messenger
            </Typography>
          </>
        ))}
    </Box>
  );

  return (
    <Grid
      item
      container
      justify={
        ((showUploading || footerInfo) && !responsive.mdScreen) || isAdmin
          ? 'space-between'
          : responsive.mdScreen
          ? 'center'
          : 'flex-end'
      }
      alignContent="center"
      alignItems={responsive.mobileScreen && showUploading ? 'flex-start' : 'center'}
      spacing={1}
      style={{
        position: 'relative',
        height: isHighFooter ? 217 : 117,
        paddingRight: responsive.mobileScreen ? 0 : 48,
        paddingLeft: responsive.mobileScreen ? 0 : 48,
      }}
    >
      {displayOnlyStep5 ? (
        renderDisplayOnlyStep5Content()
      ) : (
        <>
          <Grid
            item
            container
            xs={isHighFooter ? 12 : 6}
            alignContent="center"
            alignItems="center"
            justify={responsive.mdScreen ? 'center' : 'flex-start'}
            spacing={1}
          >
            {showUploading && (
              <Grid
                item
                container
                xs={responsive.mobileScreen ? 12 : 6}
                alignContent="center"
                justify="center"
                style={{
                  textAlign: responsive.mdScreen && isAdmin ? 'center' : 'start',
                  height: showUploading ? 117 : 100,
                }}
              >
                <UploadingBarFooter processingInfo={processingInfo} />
              </Grid>
            )}

            {footerInfo && !isAdmin && !isAdminInTheWizard && (
              <Grid
                item
                container
                xs
                alignContent={footerInfo && responsive.mdScreen ? 'flex-end' : 'center'}
                justify="center"
                style={{
                  textAlign: responsive.mdScreen ? 'center' : 'start',
                  height: showUploading ? 117 : 100,
                }}
              >
                {renderFooterInfo()}
              </Grid>
            )}

            {showLAInfo &&
              (isAdmin ? (
                renderListingAgentInfo()
              ) : (
                <Grid
                  item
                  container
                  xs
                  alignContent={footerInfo && responsive.mdScreen ? 'flex-end' : 'center'}
                  justify="center"
                  style={{
                    textAlign: responsive.mdScreen ? 'center' : 'start',
                    height: showUploading ? 117 : 100,
                  }}
                >
                  <Typography variant="h5">Admin is currently editing this plumBid</Typography>
                </Grid>
              ))}
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={isHighFooter ? 12 : 6}
            lg={6}
            spacing={1}
            justify={responsive.mdScreen ? 'center' : 'flex-end'}
            alignContent="center"
            alignItems="center"
            style={{
              height: 108,
              marginLeft: responsive.mobileScreen && 20,
              marginRight: responsive.mobileScreen && 20,
            }}
          >
            {isAdmin ? (
              <Grid item xs={4}>
                <Button
                  type="submit"
                  fullWidth
                  marginLeft={responsive.mobileScreen ? 1 : 2}
                  marginRight={responsive.mobileScreen ? 1 : 2}
                  title="Exit"
                  align="center"
                  onClick={onExit}
                />
              </Grid>
            ) : (
              <Grid item xs={isFirstStep ? 3 : true}>
                <Button
                  fullWidth
                  variant="secondary"
                  marginLeft={responsive.mobileScreen ? 1 : 2}
                  marginRight={responsive.mobileScreen ? 1 : 2}
                  title="Cancel"
                  align="center"
                  onClick={onCancel}
                />
              </Grid>
            )}
            {!isFirstStep && (
              <Grid item xs={isAdmin ? 3 : true}>
                <Button
                  fullWidth
                  variant="secondary"
                  title="Back"
                  align="center"
                  marginLeft={responsive.mobileScreen ? 1 : 2}
                  marginRight={responsive.mobileScreen ? 1 : 2}
                  onClick={onPrevStep}
                  disabled={nextLoading || ((currentStep === 4 || currentStep === 3) && isNextDisabled)}
                />
              </Grid>
            )}
            {isAdmin ? (
              currentStep !== 5 && (
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    variant="secondary"
                    title="Continue"
                    align="center"
                    marginLeft={responsive.mobileScreen ? 1 : 2}
                    marginRight={responsive.mobileScreen ? 1 : 2}
                    onClick={onNextStep}
                    disabled={nextLoading}
                  />
                </Grid>
              )
            ) : (
              <Grid item xs={5}>
                {currentStep === 2 ? (
                  <CustomToolTip
                    open={processingInfo.isRunProcessing}
                    arrow
                    placement="top"
                    title='While the offers are being processed, press "Continue" to complete the initial setup of your plumBid.'
                    TransitionComponent={Zoom}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <div style={{ width: '100%' }}>
                      <Button
                        type="submit"
                        fullWidth
                        marginLeft={responsive.mobileScreen ? 1 : 3}
                        marginRight={responsive.mobileScreen ? 1 : 3}
                        title="Continue"
                        icon={continueButtonIcon}
                        onClick={onNextStep}
                        disabled={isDisabled}
                        loading={nextLoading}
                        style={{
                          minWidth: 'fit-content',
                        }}
                      />
                    </div>
                  </CustomToolTip>
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    marginLeft={responsive.mobileScreen ? 1 : 3}
                    marginRight={responsive.mobileScreen ? 1 : 3}
                    title={nextButtonTitle}
                    icon={continueButtonIcon}
                    onClick={currentStep >= 5 ? (!mlsId ? onFirstStep : onFinishStep) : onNextStep}
                    disabled={isDisabled}
                    loading={nextLoading}
                    style={{
                      minWidth: 'fit-content',
                    }}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};
