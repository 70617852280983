import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { CONTACTS } from 'routes';

import { LinkButton, Icons, PBox, RouterLink } from 'legos';
import { useResponsive } from 'utils/hooks';
import { theme } from 'utils/theme';

export const Footer = () => {
  const history = useHistory();
  const { mobileScreen, mdScreen, padScreen, tabletScreen } = useResponsive();

  return (
    <PBox bgcolor={theme.palette.background.footer} maxWidth="1440px">
      <Grid container>
        <Grid container item xs={12} style={{ padding: mdScreen ? 10 : '20px 30px' }} justify="space-between">
          <Grid
            container
            item
            xs={12}
            sm={mobileScreen ? 12 : 6}
            justify={mobileScreen ? 'space-between' : 'flex-start'}
            style={{ order: mobileScreen ? 2 : 1 }}
          >
            <Grid item>
              <Typography variant="h5" style={{ color: theme.palette.common.white }}>
                Social Links
              </Typography>
              <PBox display="flex" mt={1}>
                <a
                  href="https://www.facebook.com/PlumBid-Multiples-101505352045341"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  <PBox
                    mr={1}
                    width="36px"
                    height="36px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor={theme.palette.background.footerBottom}
                  >
                    <Icons.Facebook />
                  </PBox>
                </a>
                <a
                  href="https://www.linkedin.com/company/plumbid/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  <PBox
                    ml={1}
                    width="36px"
                    height="36px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor={theme.palette.background.footerBottom}
                  >
                    <Icons.LinkedIn style={{ fill: theme.palette.background.paper, marginBottom: 2 }} />
                  </PBox>
                </a>
              </PBox>
            </Grid>
            <Grid item style={{ width: padScreen ? '50%' : '40%' }}>
              <Typography variant="h5" style={{ color: theme.palette.common.white, paddingLeft: 24 }}>
                plumBid: the multiple offer in which everyone wins.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={mobileScreen ? 12 : 6}
            justify={mobileScreen ? 'space-between' : 'flex-end'}
            spacing={2}
            style={{ order: mobileScreen ? 1 : 2, paddingBottom: mobileScreen ? 12 : 0 }}
          >
            <Grid item container alignItems="flex-start" xs={6}>
              <Grid item xs={1}>
                <PBox width="25px">
                  <Icons.Address />
                </PBox>
              </Grid>
              <Grid item xs>
                <Typography variant="h5" style={{ color: theme.palette.common.white, paddingLeft: 8 }}>
                  200 South Los Robles Avenue, Suite 410 Pasadena, California 91101
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="column"
              xs={padScreen ? 6 : 3}
              md={6}
              lg={tabletScreen ? 4 : 3}
              justify="flex-start"
            >
              <a href="tel:626.229.9700" style={{ textDecoration: 'none' }}>
                <PBox display="flex" pb={2}>
                  <PBox width="25px">
                    <Icons.Telephone />
                  </PBox>
                  <Typography
                    variant="h5"
                    style={{
                      color: theme.palette.common.white,
                      paddingLeft: 8,
                    }}
                  >
                    626.229.9700
                  </Typography>
                </PBox>
              </a>
              <a href="mailto:info@plumbid.com" style={{ textDecoration: 'none' }}>
                <PBox display="flex">
                  <PBox width="25px">
                    <Icons.EmailFooter />
                  </PBox>
                  <Typography
                    variant="h5"
                    style={{
                      color: theme.palette.common.white,
                      paddingLeft: 8,
                    }}
                  >
                    info@plumbid.com
                  </Typography>
                </PBox>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{
            padding: '5px 0 5px 15px',
            background: theme.palette.background.footerBottom,
          }}
          alignItems="center"
          justify="space-between"
        >
          <Grid item container xs={4}>
            <Typography variant="h5" style={{ color: theme.palette.common.white, paddingRight: 16 }}>
              plumBid Inc.
            </Typography>
            <Typography variant="h5" style={{ color: theme.palette.common.white }}>
              Copyright 2022, 2016-2022
            </Typography>
          </Grid>
          <Grid item container xs={8} md={5} justify="space-evenly" alignItems="center">
            <RouterLink
              className="link-in-footer"
              to="/term-of-use"
              style={{ color: theme.palette.common.white, fontSize: 14 }}
            >
              Terms & Conditions
            </RouterLink>
            <RouterLink
              className="link-in-footer"
              to="/privacy-policy"
              style={{ color: theme.palette.common.white, fontSize: 14 }}
            >
              Privacy Policy
            </RouterLink>
            <LinkButton
              color={theme.palette.common.white}
              title="Interested: Contact us"
              onClick={() => history.push(CONTACTS)}
            />
            <a
              href="https://demigos.com/"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: 'none',
                color: theme.palette.background.suvaGrey,
                fontSize: 14,
              }}
            >
              Developed by Demigos
              <Icons.Demigos style={{ paddingLeft: 4, verticalAlign: 'middle' }} />
            </a>
          </Grid>
        </Grid>
      </Grid>
    </PBox>
  );
};
