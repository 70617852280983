import React, { Fragment, memo } from 'react';

import { NEW_PARTY_STATUS, ROLES } from 'constant';
import { SellerPriorities, WarningBlock, PBox, PriorityScale } from 'legos';
import { SellersParty } from './SellersParty';

const listPriorities = [
  { percentField: 'cash', field: 'amountDown', title: 'Seller Cash Down Priority' },
  {
    percentField: 'escrow',
    fieldLength: 'idealEscrowLength',
    field: 'escrowPriority',
    title: 'Seller Escrow Priority:',
  },
  { percentField: 'inspection', field: 'inspectionPriority', title: 'Seller Inspection Priority' },
  { percentField: 'loan', field: 'loanContingencyPriority', title: 'Seller Loan Contingency Priority' },
  { percentField: 'appraisal', field: 'appraisalContingencyPriority', title: 'Seller Appraisal Contingency Priority' },
  {
    percentField: 'leaseback',
    fieldLength: 'leaseBackLength',
    field: 'leaseBackImportance',
    title: 'Seller Lease Back Importance:',
  },
  { percentField: 'cop', field: 'sellerContingencyPriority', title: 'Seller Contingency on Purchase (COP) Priority' },
];

export const SellerSection = memo(({ plumBid, refetch, loading }) => {
  const isSellerOnboardingCompleted = plumBid.sellerParty.status === NEW_PARTY_STATUS.COMPLETED;
  const sellerPriorities = plumBid.sellerParty.sellerPriorities;
  const canDisplayForBuyer =
    (plumBid.myInfoInPlumBid.role === ROLES.BUYER || plumBid.myInfoInPlumBid.role === ROLES.BUYER_AGENT) &&
    !isSellerOnboardingCompleted;

  const renderPriorities = () => (
    <>
      <PriorityScale />
      {listPriorities.map(priority =>
        isSellerOnboardingCompleted && sellerPriorities?.percentage?.[priority.percentField] === 0 ? null : (
          <Fragment key={priority.field}>
            <SellerPriorities
              process={isSellerOnboardingCompleted ? sellerPriorities?.percentage?.[priority.percentField] : 0}
              title={`${priority.title} ${
                sellerPriorities?.[priority?.fieldLength]
                  ? sellerPriorities?.[priority?.fieldLength] +
                    ` ${
                      sellerPriorities?.[priority?.fieldLength] > 1 || sellerPriorities?.[priority?.fieldLength] === 0
                        ? 'days'
                        : 'day'
                    } Preferred`
                  : ''
              } `}
            />
          </Fragment>
        )
      )}
    </>
  );

  return (
    <PBox bgcolor="white" width="100%" p={3.75}>
      <SellersParty plumBid={plumBid} refetch={refetch} loading={loading} />
      {canDisplayForBuyer
        ? null
        : !isSellerOnboardingCompleted && (
            <PBox mt={2}>
              <WarningBlock
                style={{ padding: 8 }}
                variant="warning"
                text="Seller preferences will be displayed once all sellers have completed their onboarding"
              />
            </PBox>
          )}
      <PBox width="100%" mt={2}>
        {canDisplayForBuyer ? null : renderPriorities()}
      </PBox>
    </PBox>
  );
});
