import React from 'react';

import { Grid, Box, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { NavigationButton, Logo } from 'legos';
import { DialogHeader } from './DialogHeader';
import state from 'service/graphql/state';
import { useReactiveVar } from '@apollo/client';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },

  drawerPaper: {
    [theme.breakpoints.up('sm')]: {
      width: theme.breakpoints.values.sm,
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexShrink: 0,
    },
  },
}));

export const HeaderMobile = ({ style, children, handleClick, handleClose, open }) => {
  const isActiveSkip = useReactiveVar(state.tutorialSkipRunVar);
  const classes = useStyles();

  return (
    <Grid
      container
      item
      xs={1}
      alignItems="center"
      justify="flex-end"
      id={isActiveSkip ? 'tutorial__OpenMenuSkip' : 'tutorial__OpenMenu'}
    >
      <Box
        height="59px"
        width="65px"
        style={{
          position: 'absolute',
          ...style,
        }}
        display="flex"
        alignItems="center"
        justifyContent=" center"
      >
        <NavigationButton
          icon="Hamburger"
          onClick={handleClick}
          iconSize={30}
          style={{
            height: '100%',
            width: '100%',
          }}
        />
      </Box>
      <Drawer
        variant="temporary"
        anchor="right"
        open={isActiveSkip ? isActiveSkip : open}
        onClose={handleClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <DialogHeader onClose={handleClose} height={60} logo={<Logo />} />
        <Grid container item direction="column">
          {children}
        </Grid>
      </Drawer>
    </Grid>
  );
};
