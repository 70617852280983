import { Box, Grid, IconButton, Typography } from '@material-ui/core';

import { useResponsive } from 'utils/hooks';
import { Icons } from 'legos';
import backgroundHeroSection from 'assets/images/happyWomanInChair.jpg';
import smartMockupsHomeFullMac from 'assets/images/smartMockupsHomeFullMac.png';
import { DIALOG } from 'constant';
import { useDialog } from 'utils/hooks';
import { withStyles } from '@material-ui/styles';

const CustomIconButton = withStyles(theme => ({
  root: {
    backgroundColor: '#731E6BF0',
    transition: '1s',
    '&:hover': {
      backgroundColor: '#731E6B',
      boxShadow: '0 0 10px #731E6BF0',
      transition: '0.5s',
    },
  },
}))(IconButton);

export const HeroSection = () => {
  const { mobileScreen, padScreen, smallScreen, tabletScreen, mdScreen } = useResponsive();
  const { openDialog: openVideoDialog } = useDialog(DIALOG.VIDEO);

  const playVideo = () => {
    openVideoDialog({
      video: 'https://www.youtube.com/watch?v=UgPGybD12-0&ab_channel=InfoPlumbid',
      title: 'How plumBid Works',
    });
  };

  return (
    <Box>
      <Box
        height="400px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundImage: `url(${backgroundHeroSection})`,
          backgroundSize: mobileScreen ? 'cover' : '133% ',
          backgroundPosition: smallScreen ? 'center' : 'left',
          backgroundPositionY: '48%',
        }}
      >
        <Grid container>
          <Grid container item xs={smallScreen ? 12 : 8} md={6} justify="flex-end">
            <Box
              maxWidth="675px"
              ml={2}
              mr={smallScreen ? 2 : 'unset'}
              px={mobileScreen ? 3 : 4}
              py={mobileScreen ? 1.5 : 3}
              bgcolor={smallScreen ? '#ffffff99' : '#ffffff'}
              borderRadius="60px"
            >
              <Typography variant="h1" style={{ fontSize: mobileScreen ? 24 : padScreen ? 28 : 34, fontWeight: 700 }}>
                Welcome to your best multiple offer.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container style={{ paddingTop: 80, paddingBottom: 30 }}>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="center" style={{ position: 'relative' }}>
            <img
              style={{ width: padScreen ? '100%' : tabletScreen ? '90%' : '75%' }}
              src={smartMockupsHomeFullMac}
              srcSet={`${smartMockupsHomeFullMac} 2x`}
              alt=""
            />
            <CustomIconButton
              className="play-button"
              style={{
                position: 'absolute',
                height: smallScreen ? 50 : mdScreen ? 70 : padScreen ? 60 : 72,
                width: smallScreen ? 50 : mdScreen ? 70 : padScreen ? 60 : 72,
                left: padScreen ? '45%' : tabletScreen ? '42%' : '45%',
                top: mdScreen ? '42%' : padScreen ? '35%' : '38%',
              }}
              onClick={playVideo}
            >
              <Icons.Play
                style={{
                  marginLeft: 6,
                  height: 36,
                  width: 36,
                }}
              />
            </CustomIconButton>
          </Box>
        </Grid>
        <Grid item container xs={12} md={6}>
          <Box mt={{ xs: 4, md: 0 }} mr={{ xs: 0, lg: 5 }} pr={{ xs: 2, md: 8 }} pl={{ xs: 2, md: 1 }}>
            <Typography variant="h3" style={{ fontSize: 23, lineHeight: '39px', paddingBottom: 36 }}>
              In real estate, multiple offers can be the least transparent, most confusing and time consuming part of
              buying a home.
            </Typography>
            <Typography variant="h3" style={{ fontSize: 23, lineHeight: '39px' }}>
              Revolutionary and patent-pending plumBid removes the guesswork and puts seller, buyer and agent in control
              of the entire process, maximizing the results for everyone.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
