export const HOME = '/';
export const PLUMBID = '/plumbid';
export const BID_HISTORY = '/bid-history';
export const DASHBOARD = '/dashboard';
export const DEV = '/dev';
export const INVITE = '/invite';
export const ADMIN = '/pbadmin';
export const ADMIN_USERS = `${ADMIN}/users`;
export const ADMIN_PLUMBID = `${ADMIN}/plumbids`;
export const ADMIN_DASHBOARD = `${ADMIN}/dashboard`;
export const ADMIN_CHATS = `${ADMIN}/chats`;
export const ADMIN_USER_PLUMBIDS = `${ADMIN}/user`;
export const ADMIN_BID_HISTORY = `${ADMIN}/bid-history`;
export const USER_SANDBOX = '/sandbox-dashboard';
export const ADMIN_SANDBOX = `${ADMIN}/sandbox-dashboard`;
export const SANDBOX_PLUMBID = '/sandbox-plumbid';
export const SETTINGS = '/settings';
export const BUYERS = '/buyers';
export const AGENTS = '/agents';
export const SELLERS = '/sellers';
export const SELLER_ONBOARDING = '/seller-onboarding';
export const BUYER_ONBOARDING = '/buyer-onboarding';
export const RESET_PASSWORD = '/reset-password';
export const CONTACTS = '/contacts';
export const ABOUT_US = '/about-us';
export const CREATE_PASSWORD = '/create-password';
export const WIZARD = '/wizard';
export const ADMIN_WIZARD = `${ADMIN}/wizard`;
export const REVIEW_BUYERS_ONBOARDING = '/review-buyers-onboarding';
export const PRIVACY_POLICY = '/privacy-policy';
export const TERM_OF_USE = '/term-of-use';
