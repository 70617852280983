import { Box, Typography, Divider, TableContainer, Table, TableBody } from '@material-ui/core';

import { ROLES } from 'constant';
import { formatNumberToUSD } from 'utils/formatters';
import { HistoryTableBody } from 'components';
import { getInitials, formatUserFullName } from 'utils/formatters';
import { theme } from 'utils/theme';
import HistoryTableHead from './HistoryTableHead';

export const WinnerItem = ({ bids, bid, myRole, isMyBidHighest, myPartyId }) => {
  const hideFullName = bids[0].partyId !== +myPartyId;
  return (
    <Box
      my={2}
      p={4}
      border={`3px solid ${theme.palette.primary.main}`}
      borderRadius="15px"
      bgcolor={theme.palette.background.card}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h2" style={{ color: theme.palette.primary.main }}>
          {`Winner - ${
            (ROLES.BUYER_AGENT === myRole && hideFullName) || (ROLES.BUYER === myRole && !isMyBidHighest)
              ? getInitials(bid?.owner?.user?.fullName)
              : formatUserFullName(bid?.owner?.user)
          } ${
            ROLES.BUYER === myRole && !isMyBidHighest
              ? formatNumberToUSD(bid.plumBidAmount, { hidePlusSign: true })
              : ''
          }`}
        </Typography>
      </Box>
      {ROLES.BUYER === myRole && !isMyBidHighest ? null : (
        <>
          <Box pt={4}>
            <Divider />
            <TableContainer>
              <Table stickyHeader aria-label="bids table">
                <HistoryTableHead />
                <TableBody>
                  <HistoryTableBody bid={bids[0]} myRole={myRole} myPartyId={myPartyId} />
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </Box>
  );
};
