import { Footer } from 'components';
import { LearnMore } from './LearnMore';
import { HeroSection } from './HeroSection';

export const HomePageContainer = () => {
  return (
    <>
      <HeroSection />
      <LearnMore />
      <Footer />
    </>
  );
};
