import { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Typography } from '@material-ui/core';

import { WIZARD } from 'routes';
import { DIALOG, ROLES, PLUMBID_STATUS } from 'constant';
import { BuyerPartyItem } from './BuyerPartyItem';
import { BuyersStatistic } from './BuyersStatistic';
import { BuyerPartyStatus } from './BuyerPartyStatus';
import { BuyerSectionHeader } from './BuyerSectionHeader';
import { BuyerParticipantItem } from './BuyerParticipantItem';
import { ContingencyTag } from 'components/ContingencyTag';
import { theme } from 'utils/theme';
import { useDialog, useResponsive } from 'utils/hooks';
import { LinkButton, PBox } from 'legos';

export const BuyerSection = memo(({ plumBid }) => {
  const myInfo = plumBid.myInfoInPlumBid;
  const isBuyerView = [ROLES.BUYER, ROLES.BUYER_AGENT].some(role => role === myInfo.role);
  const isShowButtonAddOffer =
    (myInfo.role === ROLES.ADMIN || myInfo.role === ROLES.LISTING_AGENT) &&
    (plumBid.status === PLUMBID_STATUS.LIVE || plumBid.status === PLUMBID_STATUS.UPCOMING);
  const { mobileScreen } = useResponsive();
  const history = useHistory();
  const { openDialog: openReviewOfferDialog } = useDialog(DIALOG.REVIEW_OFFER);

  const handleViewOffer = useCallback(
    party => {
      if (myInfo.isBuyerParty) {
        openReviewOfferDialog({
          party: myInfo.myParty,
          offerSettings: { values: myInfo.myParty.offersettings, errors: {} },
        });
      } else {
        const state = { displayOnlyStep5: true, plumBidId: plumBid.id, isAddOffer: party ? false : true };
        state.selectedParty = party ?? undefined;
        history.push({
          pathname: WIZARD,
          state,
        });
      }
    },
    [history, openReviewOfferDialog, plumBid.id, myInfo.isBuyerParty, myInfo.myParty]
  );

  const participantSet = myInfo?.myParty?.participantSet?.reduce(
    (acc, participant) => {
      if (participant.role === ROLES.BUYER) {
        acc.buyers.push(participant);
      }
      if (participant.role === ROLES.BUYER_AGENT) {
        acc.buyerAgents.push(participant);
      }
      return acc;
    },
    { buyers: [], buyerAgents: [] }
  );

  const renderParticipants = () => (
    <>
      {participantSet?.buyers.map(participant => (
        <BuyerParticipantItem key={participant.id} plumBid={plumBid} participant={participant} party={myInfo.myParty} />
      ))}
      {participantSet?.buyerAgents.map(participant => (
        <BuyerParticipantItem key={participant.id} plumBid={plumBid} participant={participant} party={myInfo.myParty} />
      ))}
    </>
  );

  return (
    <PBox bgcolor="white" width="100%" p={3.75}>
      <BuyerSectionHeader plumBid={plumBid} />
      <PBox
        display="flex"
        height="30px"
        mt={4}
        mb={mobileScreen ? 2 : 0}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" style={{ color: theme.palette.text.grey }}>
          {myInfo.role === ROLES.LISTING_AGENT || myInfo.role === ROLES.SELLER ? 'General status' : 'Party'}
        </Typography>
        <PBox
          display="flex"
          alignItems="center"
          maxWidth={mobileScreen ? '170px' : 'auto'}
          flexDirection={mobileScreen ? 'column' : 'row'}
        >
          {isBuyerView && myInfo.myParty.offersettings?.agreementContingent && (
            <PBox mb={mobileScreen ? 1 : 0}>
              <ContingencyTag contingencyRemoved={myInfo.myParty?.secondaryContingencyRemoved} />
            </PBox>
          )}
          <BuyerPartyStatus plumBid={plumBid} />
          {isShowButtonAddOffer ? (
            <PBox ml={2} textAlign="end">
              <LinkButton
                title="Add Offer"
                icon="CirclePlus"
                iconSize={13}
                color="link"
                style={{ fontWeight: 500 }}
                onClick={() => handleViewOffer()}
              />
            </PBox>
          ) : null}
        </PBox>
      </PBox>
      {myInfo.role !== ROLES.SELLER && (
        <PBox pt={2}>
          {isBuyerView
            ? renderParticipants()
            : plumBid.buyerParty.length > 0
            ? plumBid.buyerParty.map(party => (
                <BuyerPartyItem key={party.id} party={party} plumBid={plumBid} handleViewOffer={handleViewOffer} />
              ))
            : null}
        </PBox>
      )}
      {myInfo.role === ROLES.SELLER && isBuyerView && (
        <PBox pt={3.75}>
          <BuyersStatistic plumBid={plumBid} />
        </PBox>
      )}
    </PBox>
  );
});
