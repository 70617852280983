import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { useSettingsState } from 'service/store';
import { HAS_PLUMBIDS } from 'service/graphql';
import { Button } from 'legos';
import { ADMIN_DASHBOARD, DASHBOARD } from 'routes';
import { ROLES } from 'constant';
import { useResponsive } from 'utils/hooks';

export const DashboardButton = memo(
  ({ me, isShowGoBackButton }) => {
    const history = useHistory();

    const [{ isOpenDrawer }, dispatchSettings] = useSettingsState();
    const responsive = useResponsive();

    const { data, loading } = useQuery(HAS_PLUMBIDS, {
      skip: !me?.userId,
      fetchPolicy: 'network-only',
    });

    const handleProceed = event => {
      if (me) {
        if (me?.roles?.includes(ROLES.ADMIN)) {
          history.push(ADMIN_DASHBOARD);
        } else {
          history.push(DASHBOARD);
        }
      } else {
        dispatchSettings({
          type: 'toggleAuthDrawer',
          payload: {
            isOpenDrawer: !isOpenDrawer,
            drawerScreen: 'SignUpScreen',
          },
        });
      }
    };

    return me?.roles?.includes(ROLES.ADMIN) || (data?.hasPlumbids && !loading) ? (
      <Button
        title={me ? 'Dashboard' : 'Agents, register your multiple offer listing'}
        fullWidth={responsive.padScreen}
        align="center"
        style={{
          borderRadius: '4px',
          fontWeight: 500,
          height: 39,
          minWidth: isShowGoBackButton ? 200 : 230,
        }}
        onClick={() => handleProceed()}
      />
    ) : null;
  },
  (prevProps, nextProps) => prevProps.me?.userId === nextProps.me?.userId
);
