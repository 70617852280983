import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { useEffect, useState } from 'react';
import { useReactiveVar, useMutation } from '@apollo/client';
import { Typography, IconButton, InputAdornment, Grid, Box } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';

import { PLUMBID_STATUS, ROLES } from 'constant';
import state from 'service/graphql/state';
import { SET_PLUMBID_DATES_MUTATION } from 'service/graphql';
import { LinkButton, Icons, Button, PBox } from 'legos';
import { PlumBidStatusCard } from './PlumBidStatusCard';
import { useNotification } from 'utils/hooks';
import { useAuthState } from 'service/store/authStore';
import { theme } from 'utils/theme';

const validateDateRange = finishDate =>
  finishDate?.isBetween(
    moment().set('minute', Math.floor(moment().get('minute') / 15) * 15 + 14),
    moment().add(7, 'd').set('hour', 23).set('minute', 59).set('second', 59)
  );

export const PlumBidStatus = ({ plumBid, refetch }) => {
  const [{ me }] = useAuthState();
  const isAdmin = me.roles.includes(ROLES.ADMIN);
  const isPlumBidCanceled = plumBid.status === PLUMBID_STATUS.CANCELED;
  const showEditOrRelaunchButton =
    (isAdmin || plumBid?.myInfoInPlumBid.role === ROLES.LISTING_AGENT) &&
    (plumBid.status === PLUMBID_STATUS.UPCOMING || plumBid.status === PLUMBID_STATUS.LIVE || isPlumBidCanceled);

  const [editFinishPlumBid, setEditFinishPlumBid] = useState(false);
  const [finishDateTime, setFinishDateTime] = useState(plumBid.finishDateTime);
  const [isValidDate, setIsValidDate] = useState(validateDateRange(moment(plumBid.finishDateTime)));
  const showNotification = useNotification();
  const plumBidTimeLeft = useReactiveVar(state.plumBidsTimerLeftVar);
  const timeLeft = plumBidTimeLeft[`${plumBid.id}dashboard`];

  const renderTimeLeft = ms => {
    if (ms < 1000) {
      return '';
    }
    let s = Math.floor(ms / 1000);
    let m = Math.floor(s / 60);
    let h = Math.floor(m / 60);
    const d = Math.floor(h / 24);

    h -= d * 24;
    m -= (d * 24 + h) * 60;
    s -= ((d * 24 + h) * 60 + m) * 60;

    return (
      <Box display="flex" flexWrap="wrap">
        {d > 0 && (
          <PBox pr={0.5}>
            <Typography variant="body1" style={{ fontSize: 16, fontWeight: 700 }}>
              {`${d} ${d === 1 ? 'day' : 'days'}`}
            </Typography>
          </PBox>
        )}
        {h > 0 && (
          <PBox pr={0.5}>
            <Typography variant="body1" style={{ fontSize: 16, fontWeight: 700 }}>
              {`${h} ${h === 1 ? 'hour' : 'hours'}`}
            </Typography>
          </PBox>
        )}
        <PBox pr={0.5}>
          <Typography variant="body1" style={{ fontSize: 16, fontWeight: 700 }}>
            {`${m} ${m === 1 ? 'minute' : 'minutes'}`}
          </Typography>
        </PBox>
        {d === 0 && (
          <PBox>
            <Typography variant="body1" style={{ fontSize: 16, fontWeight: 700 }}>
              {`${s} ${s === 1 ? 'second' : 'seconds'}`}
            </Typography>
          </PBox>
        )}
      </Box>
    );
  };

  const [setPlumbidDates] = useMutation(SET_PLUMBID_DATES_MUTATION);

  const statusInfo =
    plumBid.status === PLUMBID_STATUS.UPCOMING || plumBid.status === PLUMBID_STATUS.INCOMPLETE
      ? (plumBid.finishDateTime && {
          line1: 'plumBid will end ',
          line2: moment(plumBid.finishDateTime).format('ddd, MMM DD [at] h:mm a '),
        }) || { line1: '', line2: '' }
      : plumBid.status === PLUMBID_STATUS.LIVE && !!timeLeft
      ? { line1: 'plumBid will finish in', line2: 'timeLeft' }
      : { line1: 'plumBid Ended', line2: '' };

  const changeFinishDatePlumBid = date => {
    if (validateDateRange(date)) {
      setPlumbidDates({
        variables: { input: { plumbidId: plumBid.id, finishDateTime: date } },
      })
        .catch(() => {
          showNotification({
            message: 'Something went wrong',
            variant: 'error',
          });
        })
        .then(({ data }) => {
          if (data.setPlumbidDates.success) {
            if (!isAdmin) {
              refetch();
            }
            showNotification({
              message: 'Finish date changed',
              variant: 'success',
            });
          }
        })
        .finally(setEditFinishPlumBid(false));
    } else {
      showNotification({
        message: 'Please select time in the future',
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (plumBid.status === PLUMBID_STATUS.FINISHED && !isAdmin) {
      refetch();
    }
  }, [isAdmin, plumBid.status, refetch]);

  return (
    <Grid container item direction={plumBid.status === PLUMBID_STATUS.UPCOMING ? 'row' : 'column'}>
      <PlumBidStatusCard plumBid={plumBid} timeLeft={timeLeft} />
      <PBox
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={editFinishPlumBid || isPlumBidCanceled ? 'column' : 'row'}
        mt={1}
      >
        <PBox mt={1} alignSelf="flex-start" width="100%">
          {!editFinishPlumBid && (
            <PBox width="100%">
              <PBox display="flex" alignItems="center" justifyContent="space-between">
                <PBox pb={0.5}>
                  <Typography align="left" style={{ fontSize: 12, fontWeight: 500 }}>
                    {statusInfo.line1}
                  </Typography>
                </PBox>
                {showEditOrRelaunchButton && (
                  <PBox>
                    {isPlumBidCanceled ? (
                      <Button
                        title="Relaunch"
                        icon="Edit"
                        fullWidth
                        style={{ margin: '8px 0' }}
                        onClick={() => setEditFinishPlumBid(true)}
                      />
                    ) : (
                      <LinkButton
                        icon="EditPencil"
                        iconSize={16}
                        color="link"
                        style={{
                          marginLeft: 20,
                          padding: 0,
                        }}
                        onClick={() => setEditFinishPlumBid(true)}
                      />
                    )}
                  </PBox>
                )}
              </PBox>
              <PBox mb={1} mt={2}>
                {statusInfo.line2 === 'timeLeft' ? (
                  renderTimeLeft(timeLeft)
                ) : (
                  <Typography align="left" style={{ fontSize: 18, fontWeight: 700 }}>
                    {statusInfo.line2}
                  </Typography>
                )}
              </PBox>
            </PBox>
          )}
          {editFinishPlumBid && (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                showDaysOutsideCurrentMonth
                fullWidth
                orientation="portrait"
                variant="dialog"
                format="MM/DD/yyyy"
                minDate={moment()}
                maxDate={moment().add(7, 'day')}
                value={finishDateTime}
                onChange={date => {
                  setFinishDateTime(date);
                  setIsValidDate(validateDateRange(date));
                }}
                inputVariant="filled"
                style={{ marginBottom: 8 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <Icons.CalendarNew />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TimePicker
                orientation="portrait"
                fullWidth
                id="time"
                value={finishDateTime}
                onChange={date => {
                  setFinishDateTime(date);
                  setIsValidDate(validateDateRange(date));
                }}
                inputVariant="filled"
                minutesStep={15}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <Icons.Clock />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {!isValidDate && (
                <PBox px={1.75} mt={0.5}>
                  <Typography variant="h5" style={{ color: theme.palette.error.main, fontWeight: 700 }}>
                    Please select time in the future
                  </Typography>
                </PBox>
              )}
            </MuiPickersUtilsProvider>
          )}
        </PBox>
        {showEditOrRelaunchButton ? (
          <PBox width={editFinishPlumBid || isPlumBidCanceled ? '100%' : 'auto'}>
            {editFinishPlumBid && (
              <PBox width="100%">
                <Button
                  fullWidth
                  title="Save"
                  icon="Accept"
                  style={{ margin: '8px 0' }}
                  disabled={!isValidDate}
                  onClick={() => changeFinishDatePlumBid(finishDateTime)}
                />
                <Button
                  fullWidth
                  title="Cancel"
                  icon="Close"
                  variant="secondary"
                  onClick={() => setEditFinishPlumBid(false)}
                />
              </PBox>
            )}
          </PBox>
        ) : null}
      </PBox>

      {plumBid.status === PLUMBID_STATUS.UPCOMING && (
        <PBox py={0.5} mt={1} width="100%">
          <Typography align="left" style={{ color: theme.palette.text.warning }}>
            plumBid will start once sellers and at least two buyers are done with the onboarding
          </Typography>
        </PBox>
      )}
    </Grid>
  );
};
