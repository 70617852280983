import { useEffect, useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Grid, Box, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { useHistory, useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import { useAuthState, useSettingsState } from 'service/store';
import state from 'service/graphql/state';
import { Drawer } from 'components';
import { RouterLink, Button, LinkButton, LogoHome, NavigationButton } from 'legos';
import { useInitials, useResponsive } from 'utils/hooks';
import { AuthNav } from './AuthNav';
import { BUYERS, AGENTS, SELLERS, CONTACTS, ABOUT_US, WIZARD, PRIVACY_POLICY, TERM_OF_USE } from 'routes';
import { LISTING_AGENT_VERIFY_STATUS, ROLES } from 'constant';
import { HeaderMobile } from './HeaderMobile';
import { DashboardButton } from './DashboardButton';
import { theme } from 'utils/theme';

const StyledMenu = withStyles({
  paper: {
    textAlign: 'center',
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'inherit',
    padding: ' 0px !important',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const Items = [
  { title: 'About', link: () => ABOUT_US },
  { title: 'Buyers', link: () => BUYERS },
  { title: 'Sellers', link: () => SELLERS },
  { title: 'Agents', link: () => AGENTS },
  { title: 'Contact', link: () => CONTACTS },
];

export const HeaderHome = () => {
  useInitials();
  const history = useHistory();
  const { pathname, state: pathState } = useLocation();
  const { padScreen, mobileScreen } = useResponsive();
  const [{ me }] = useAuthState();
  const isSaveSignUpForm = useReactiveVar(state.signUpFormVar);
  const [, dispatchSettings] = useSettingsState();

  const isShowButtonAddPlumBid =
    me?.roles?.includes(ROLES.LISTING_AGENT) &&
    (me?.isVerifiedAgent || me.listingAgentStatus === LISTING_AGENT_VERIFY_STATUS.VERIFIED);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isShowGoBackButton = pathname === PRIVACY_POLICY || pathname === TERM_OF_USE;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!padScreen) {
      setAnchorEl(null);
    }
  }, [padScreen]);

  const goBack = useCallback(() => {
    if (isSaveSignUpForm?.isSave && !me && pathState === 'invite') {
      setTimeout(
        () =>
          dispatchSettings({
            type: 'toggleAuthDrawer',
            payload: { isOpenDrawer: true, drawerScreen: 'SignUpScreen' },
          }),
        300
      );
    }
    history.goBack();
  }, [dispatchSettings, history, isSaveSignUpForm?.isSave, me, pathState]);

  const renderHeaderContent = () => (
    <>
      <Grid item style={padScreen ? { padding: '16px 8px' } : null}>
        <DashboardButton me={me} isShowGoBackButton={isShowGoBackButton} />
      </Grid>
      {isShowButtonAddPlumBid ? (
        <Grid item style={padScreen ? { padding: '16px 8px' } : null}>
          <Button
            title={me?.wizardStepInfo?.length ? 'Continue adding plumBid' : 'Add a new plumBid'}
            fullWidth={padScreen}
            align="center"
            style={{
              borderRadius: '4px',
              fontWeight: 500,
              height: 39,
              minWidth: isShowGoBackButton ? 200 : 230,
            }}
            onClick={() =>
              history.push({
                pathname: WIZARD,
              })
            }
          />
        </Grid>
      ) : null}
      <AuthNav />
    </>
  );

  const renderHeaderMobile = () => (
    <HeaderMobile handleClick={handleClick} handleClose={handleClose} open={open}>
      <Box>
        {Items.map(item => (
          <RouterLink
            onClick={handleClose}
            key={item.title}
            to={item.link()}
            style={{ textDecoration: 'none', color: theme.palette.background.tabIndicator }}
          >
            <Typography
              style={{
                padding: '16px 0 16px 56px',
                borderBottom: `1px solid ${theme.palette.background.whisper}`,
              }}
            >
              {item.title}
            </Typography>
          </RouterLink>
        ))}

        {renderHeaderContent()}
      </Box>
    </HeaderMobile>
  );

  return (
    <AppBar
      id="header"
      position="fixed"
      elevation={0}
      style={{
        justifyContent: 'center',
        height: 70,
        flexDirection: 'row',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Grid
        container
        alignItems="center"
        justify="space-between"
        style={{
          maxWidth: 1440,
          paddingTop: 4,
          paddingBottom: 4,
          paddingRight: mobileScreen ? 0 : 48,
          paddingLeft: mobileScreen ? 24 : 48,
        }}
      >
        <Grid container item xs={isShowGoBackButton ? 9 : 3} md={padScreen ? 4 : 3} alignItems="center">
          {isShowGoBackButton && (
            <NavigationButton
              icon="LeftArrow"
              fullWidth={false}
              onClick={goBack}
              color={theme.palette.common.white}
              style={{
                backgroundColor: theme.palette.purple.light,
                marginRight: 38,
              }}
            />
          )}
          <RouterLink to="/">
            <Box
              id="logoBox"
              style={{
                transition: '0.3s',
                maxHeight: isShowGoBackButton ? '130px' : '170px',
                maxWidth: isShowGoBackButton ? 130 : '198px',
              }}
            >
              <LogoHome
                size={isShowGoBackButton && mobileScreen ? '100%' : mobileScreen ? '150%' : '100%'}
                style={{ maxHeight: 72 }}
              />
            </Box>
          </RouterLink>
        </Grid>
        <Grid
          item
          container
          justify="flex-end"
          alignItems="center"
          xs={isShowGoBackButton ? 3 : 8}
          md={isShowGoBackButton || padScreen ? 8 : 9}
          spacing={1}
        >
          {padScreen ? (
            renderHeaderMobile()
          ) : (
            <>
              {renderHeaderContent()}
              <Grid item style={{ cursor: 'pointer' }}>
                <LinkButton
                  icon={open ? 'Close' : 'Hamburger'}
                  onClick={handleClick}
                  iconSize={30}
                  style={{ padding: '16px 16px', cursor: 'pointer', marginLeft: !me ? 24 : 0 }}
                />
                <StyledMenu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  style={{ padding: ' 0px !important', marginTop: '-8px' }}
                >
                  <Box py={1} bgcolor={theme.palette.primary.main}>
                    {Items.map(item => (
                      <RouterLink key={item.title} to={item.link()} style={{ textDecoration: 'none', color: 'white' }}>
                        <MenuItem onClick={handleClose} style={{ padding: '8px 46px' }}>
                          {item.title}
                        </MenuItem>
                      </RouterLink>
                    ))}
                  </Box>
                </StyledMenu>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Drawer />
    </AppBar>
  );
};
