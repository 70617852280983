import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { theme } from 'utils/theme';
import { apolloClient } from 'service/graphql/apolloClient';
import App from './App';
import reportWebVitals from './reportWebVitals';

const version = '0.0.340';

console.log(`version: ${version}`);

Sentry.init({
  dsn: 'https://c14e139c24d74e429a6730fdf0d437bf@sentry.demigos.com/19',
  integrations: [new BrowserTracing()],
  environment: 'production',
  release: version,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.info);
