import { useRef } from 'react';
import { bool, string, oneOf, shape, func, number } from 'prop-types';
import { ButtonBase, CircularProgress, Typography, Tooltip } from '@material-ui/core';

import { Icons, PBox } from 'legos';
import { useButtonStyle, useTooltipStyle } from './style';

export const Button = ({
  title,
  tooltip,
  disabled,
  loading,
  icon,
  iconAtStart,
  variant,
  style,
  onClick,
  align,
  fullWidth,
  uploadFile,
  onUploadFile,
  uploadMultiple,
  marginLeft,
  marginRight,
  children,
  textIcon,
  ...props
}) => {
  const uploadInputRef = useRef();
  const buttonStyle = useButtonStyle({ variant });

  const tooltipStyle = useTooltipStyle();

  const isDisabled = disabled || loading;
  const Icon = Icons[icon] || null;

  const handleClick = uploadFile ? () => uploadInputRef.current?.click() : onClick;

  return (
    <Tooltip
      disableHoverListener={!tooltip}
      disableTouchListener={!tooltip}
      disableFocusListener
      title={tooltip}
      arrow
      classes={tooltipStyle}
    >
      <>
        {uploadFile ? (
          <input
            id="upload-file"
            type="file"
            multiple={uploadMultiple}
            accept="application/pdf"
            ref={uploadInputRef}
            onChange={onUploadFile}
            style={{ display: 'none' }}
          />
        ) : null}
        <ButtonBase
          classes={buttonStyle}
          style={{
            height: 48,
            justifyContent: title ? align : 'center',
            ...(!title ? { width: 48 } : fullWidth ? { width: '100%' } : null),
            ...style,
          }}
          variant={variant}
          disableRipple
          disabled={isDisabled}
          onClick={handleClick}
          // {...(uploadFile ? { component: 'label', htmlFor: 'upload-file' } : null)}
          {...props}
        >
          {Icon && iconAtStart && (
            <Icon
              style={{
                flex: '0 0 25px',
                marginLeft: marginLeft ?? 8,
                marginRight: marginRight ?? 16,
              }}
              text={textIcon ? textIcon : null}
            />
          )}
          {title && (
            <PBox mr={marginRight ?? 2} ml={marginLeft}>
              <Typography
                style={{
                  fontFamily: '"Helvetica", "Roboto", "Arial", sans-serif',
                }}
                color="inherit"
              >
                {title}
              </Typography>
            </PBox>
          )}
          {Icon && !iconAtStart && (
            <Icon
              style={{
                flex: '0 0 25px',
                marginLeft: marginLeft ?? 8,
                marginRight: marginRight ?? 16,
              }}
              text={textIcon ? textIcon : null}
            />
          )}
          {loading && (
            <CircularProgress
              style={{
                backgroundColor: 'transparent',
                color: 'inherit',
                opacity: 0.6,
                position: 'absolute',
                top: 'calc(50% - 15px)',
                left: 'calc(50% - 15px)',
              }}
              size={30}
            />
          )}
          {children}
        </ButtonBase>
      </>
    </Tooltip>
  );
};

Button.propTypes = {
  disabled: bool,
  title: string,
  tooltip: string,
  loading: bool,
  icon: string,
  style: shape({}),
  onClick: func,
  align: string,
  uploadFile: bool,
  variant: oneOf(['primary', 'secondary', 'tertiary', 'bid', 'ghost', 'danger']),
  fullWidth: bool,
  marginLeft: number,
};

Button.defaultProps = {
  disabled: false,
  tooltip: '',
  title: null,
  loading: false,
  icon: null,
  style: null,
  align: 'space-between',
  onClick: () => {},
  uploadFile: false,
  variant: 'primary',
  fullWidth: false,
  marginLeft: 2,
};
