import { useState } from 'react';

import { Box, Grid, Typography, Collapse } from '@material-ui/core';

import { LinkButton } from 'legos/Buttons/LinkButton';
import { useLocation, useHistory } from 'react-router-dom';
import { AGENTS, SELLERS, USER_SANDBOX } from 'routes';
import { useResponsive } from 'utils/hooks';

import girlOnStairs from 'assets/images/girlOnStairs.jpg';
import womanWDaughter from 'assets/images/womanWDaughter.jpeg';
import UniquelyTransparentPng from 'assets/images/UniquelyTransparent.jpg';
import DescriptionOptimizerImage from 'assets/images/smartMockupsHomeRight.png';
import DescriptionOptimizerImage2x from 'assets/images/smartMockupsHomeRight2x.png';
import { theme } from 'utils/theme';

export const DescriptionOptimizer = () => {
  const { mdScreen, tabletScreen } = useResponsive();

  const [checked, setChecked] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();

  const handleChange = () => {
    setChecked(prev => !prev);
  };
  return (
    <Box>
      <Grid
        container
        style={{
          padding: mdScreen ? '0 0 30px 0' : '75px 0',
          backgroundColor: theme.palette.background.card,
        }}
      >
        <Grid item container xs={12} md={6} alignItems="center" justify="center">
          <Box px={{ xs: 3, md: 12 }} pb={{ xs: 3, md: 0 }} pt={mdScreen ? 5 : 12}>
            <Typography variant="h1" style={{ fontWeight: 700 }}>
              Explore how it works
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontSize: 18,
                lineHeight: '27px',
                padding: '26px 0',
                color: theme.palette.text.transparent,
              }}
            >
              Patent-pending plumBid gives both buyers and sellers the power to fine-tune and adjust, so everyone has
              the advantage. The seller first uses plumBid to prioritize the terms that are most important to them,
              including:
            </Typography>
            <Box pl={4}>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  color: theme.palette.text.transparent,
                }}
              >
                - Final sales price
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  color: theme.palette.text.transparent,
                }}
              >
                - Cash down from buyer
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  color: theme.palette.text.transparent,
                }}
              >
                - Length of escrow period
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  color: theme.palette.text.transparent,
                }}
              >
                - Inspection, loan and appraisal contingencies
              </Typography>
            </Box>

            <Collapse in={checked} disableStrictModeCompat>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '26px 0',
                  color: theme.palette.text.transparent,
                }}
              >
                plumBid takes the seller preferences and layers current economic conditions to determine the plumBid
                incentives -- which clearly lets all buyers know what’s important and how to craft the best and most
                compelling offer.
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  color: theme.palette.text.transparent,
                }}
              >
                Buyers are able to simultaneously adjust multiple offers -- prior to submitting each bid.
              </Typography>
              <Typography
                variant="h3"
                style={{
                  fontSize: 18,
                  lineHeight: '27px',
                  padding: '26px 0',
                  color: theme.palette.text.transparent,
                }}
              >
                Because they can see other bidders' plumBids, buyers are assured they understand the value of the
                property and are neither overbidding nor getting preempted by another buyer, especially one whose offer
                they would have been able to beat.
              </Typography>
            </Collapse>
            <LinkButton
              onClick={handleChange}
              style={{
                textDecoration: 'underline',
                fontSize: 18,
                fontWeight: 700,
                color: theme.palette.primary.main,
                transition: 0.6,
                marginTop: checked ? 0 : '24px',
              }}
            >
              {checked ? 'Show less' : 'Show more'}
            </LinkButton>
            <Box pt={2}>
              <LinkButton
                title="Try the plumBid Optimizer"
                variant="h3"
                style={{
                  textDecoration: 'underline',
                  paddingTop: 0,
                  fontSize: 18,
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                }}
                onClick={() => history.push(USER_SANDBOX)}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            style={{ width: '100%' }}
            src={DescriptionOptimizerImage}
            srcSet={`${DescriptionOptimizerImage2x} 2x`}
            alt=""
          />
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          padding: mdScreen ? '10px 0' : '70px 0',
        }}
      >
        <Grid item xs={12} md={6} style={mdScreen ? { padding: 24 } : { paddingLeft: tabletScreen ? 40 : 88 }}>
          <img
            style={{ width: '100%', borderRadius: '38px' }}
            src={pathname === AGENTS ? girlOnStairs : pathname === SELLERS ? womanWDaughter : UniquelyTransparentPng}
            alt=""
          />
        </Grid>
        <Grid item container xs={12} md={6} alignItems="center" justify="center">
          <Box px={{ xs: 3, md: 8, lg: 12 }}>
            <Typography variant="h1" style={{ fontWeight: 700 }}>
              Uniquely transparent
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontSize: 18,
                lineHeight: '27px',
                padding: '26px 0',
                color: theme.palette.text.transparent,
              }}
            >
              plumBid solves one of the most vexing issues in real estate - the lack of perceived or actual transparency
              and fairness during a transaction. Utilizing plumBid to negotiate the final price and terms is proven to
              be better for the seller, buyer and real estate agent. No guessing how many other buyers are interested or
              how much you need to pay to secure a property. No preferential treatment -- with all parties in control of
              their process and outcome.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
