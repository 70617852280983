import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { PageContainer, DashboardSandbox, Header } from 'components';
import { NavigationButton } from 'legos';
import { useSBPlumBids, usePageLoading } from 'utils/hooks';
import { useSettingsState } from 'service/store';
import { LocalStorage } from 'utils/localStorage';
import { theme } from 'utils/theme';

export const UserSandboxPage = () => {
  const [{ refreshScreen }, dispatchSettings] = useSettingsState();
  const { sbPlumBids, sbProperties, refetch, loading } = useSBPlumBids(
    {
      sandboxKey: LocalStorage.getSandboxKey(),
    },
    true
  );

  useEffect(() => {
    if (refreshScreen === 'UserSandboxPage') {
      refetch();
      dispatchSettings({
        type: 'refreshScreen',
        payload: null,
      });
    }
  }, [refreshScreen, refetch, dispatchSettings]);
  const history = useHistory();

  usePageLoading(loading, 'UserSandboxPage');

  return (
    <>
      <Header withoutAuth />
      <PageContainer>
        <Box px={6} py={6}>
          <Box display="flex" justifyContent="flex-start">
            <NavigationButton
              fullWidth={false}
              onClick={() => history.goBack()}
              icon="LeftArrow"
              size={34}
              iconSize={14}
              style={{
                background: theme.palette.primary.main,
                marginRight: 32,
              }}
              color={theme.palette.common.white}
            />

            <Typography variant="h1" style={{ textTransform: 'uppercase' }}>
              Sandbox
            </Typography>
          </Box>
          <Typography variant="h2" style={{ marginTop: 48 }}>
            Choose a Home You’d Like to Simulate a plumBid
          </Typography>
        </Box>

        <DashboardSandbox plumBids={sbPlumBids} properties={sbProperties} refetch={refetch} />
      </PageContainer>
    </>
  );
};
